/**
 * useNewPassword hook
 */

// React
import { useState } from 'react';

// Libraries
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSetRecoilState } from 'recoil';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Control, FieldErrors, useForm, UseFormHandleSubmit } from 'react-hook-form';

// Utils
import { HTTP_CODE, RESPONSE_CODE, VALIDATION_MESSAGES } from 'utils/constants';
import { createPasswordConfirmSchema } from 'utils/validation';
import { getErrorsMessage, validatePassword } from 'utils/helper';

// Recoil
import { isLoading } from 'recoil/LoadingRecoil';

// Type
import { NewPasswordRequest } from 'models/NewPasswordRequest';

// Router
import ROUTES from 'configs/route';

// Services
import AuthService from 'services/AuthService';

const { PASSWORD } = VALIDATION_MESSAGES;

// Custom validation schema
const schema = yup.object({
  password: yup
    .string()
    .required(PASSWORD.REQUIRED)
    .test('multipleErrorsPassword', validatePassword),
  password_confirm: createPasswordConfirmSchema(),
});

const useNewPassword = (): {
  control: Control<NewPasswordRequest, any>;
  errors: FieldErrors<NewPasswordRequest>;
  changePasswordSuccess: boolean;
  errorMessage: string[] | null;
  handleClickBackLogin: () => void;
  handleSubmit: UseFormHandleSubmit<NewPasswordRequest, undefined>;
  onSubmit: (_data: NewPasswordRequest) => Promise<void>;
} => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<NewPasswordRequest>({
    defaultValues: { password: '', password_confirm: '' },
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const navigate = useNavigate();
  const [errorMessage, setErrorsMessage] = useState<string[] | null>(null);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const setIsLoading = useSetRecoilState(isLoading);
  const [searchParams] = useSearchParams();

  const handleClickBackLogin = () => {
    navigate(ROUTES.LOGIN);
  };

  const onSubmit = async (data: NewPasswordRequest) => {
    setErrorsMessage(null);
    try {
      data.username = decodeURIComponent(searchParams.get('user_name') || '');
      data.confirm_code = searchParams.get('confirmation_code') || '';
      setIsLoading(true);
      await AuthService.resetPassword(data);
      setChangePasswordSuccess(true);
    } catch (error: any) {
      const results = error?.response?.data?.results;
      const responseCode = results?.error_code;
      const responseStatus = error?.response?.status;

      if (
        responseStatus === HTTP_CODE.UNPROCESSABLE &&
        responseCode === RESPONSE_CODE.CONFIRM_CODE_EXPIRED
      ) {
        const expiredTime = searchParams.get('expired_time');
        navigate(ROUTES.VERIFY_CODE_EXPIRED, { state: { expiredTime } });
      }
      const errorsMessage = getErrorsMessage(error);
      setErrorsMessage(errorsMessage);
    }
    setIsLoading(false);
  };

  return {
    control,
    errors,
    errorMessage,
    changePasswordSuccess,
    handleClickBackLogin,
    handleSubmit,
    onSubmit,
  };
};

export default useNewPassword;
