import React, { FC } from 'react';
import { Tooltip, Box, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import MobileTooltip from './mobile';

interface TooltipInputProps {
  titleTooltip?: string;
  children: React.ReactNode;
}

// Styled
const TooltipContent = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  margin: '0px',
  fontSize: '13px',
  fontWeight: 600,
  whiteSpace: 'nowrap',
});

const IconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '18px',
  height: '18px',
  borderRadius: '50%',
  background: 'white',
});

const TooltipInput: FC<TooltipInputProps> = ({ titleTooltip, children }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!titleTooltip) {
    return <Box width="100%">{children}</Box>;
  }

  return (
    <Tooltip
      title={
        <TooltipContent>
          <IconWrapper>
            <PriorityHighIcon sx={{ fontSize: '12px', width: '2rem' }} />
          </IconWrapper>
          <Typography variant="h5" fontWeight="bold">
            {titleTooltip}
          </Typography>
        </TooltipContent>
      }
      open={!isMobileScreen}
      placement="top-end"
      arrow
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: { offset: [-13, 0] },
          },
        ],
        sx: {
          '.MuiTooltip-arrow': {
            color: theme.palette.info.main,
            position: 'absolute',
            left: '8% !important',
            bottom: '-2px !important',
            transform: 'translate(0px, 0px) !important',
            height: '10px',
            width: '15px',
          },
          '.MuiTooltip-tooltip': {
            padding: '4px',
            borderRadius: '20px',
            background: theme.palette.info.main,
            color: 'black',
            paddingRight: '13px',
            whiteSpace: 'nowrap',
            marginBottom: '-6px !important',
          },
          zIndex: 90,
        },
      }}
    >
      <Box width="100%">
        {children}
        {isMobileScreen && <MobileTooltip titleTooltip={titleTooltip} />}
      </Box>
    </Tooltip>
  );
};

export default TooltipInput;
