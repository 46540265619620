/**
 * Error page
 */

// React
import React from 'react';

// Libraries
import { useNavigate } from 'react-router-dom';

// MUI imports
import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Component
import AppLogo from 'components/AppLogo';

// Router
import ROUTES from 'configs/route';

// Styled
const LogoContainer = styled(Box)({
  padding: '0 10px 30px 10px',
});

const WrapperBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '30px',
}));

const WrapperContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
});

const ButtonCustom = styled(Button)({
  width: '40%',
  padding: '10px',
  border: 'none',
  borderRadius: 0,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  maxWidth: '220px',
});

const TypographyCustom = styled(Typography)({
  fontWeight: '600',
  marginBottom: '0',
  paddingBottom: '4px',
});

const WrapperButton = styled(Box)({
  width: '100%',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '10px',
});

// Component
const ErrorPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClickBackPage = async () => {
    try {
      navigate(ROUTES.HOME);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <WrapperBox>
        <LogoContainer>
          <AppLogo />
        </LogoContainer>
        <WrapperContent>
          <TypographyCustom variant="h3">エラーが発生しました。</TypographyCustom>
          <TypographyCustom variant="h4">
            ご不便をおかけして申し訳ございません。もう一度お試しください。
          </TypographyCustom>
          <TypographyCustom variant="h4">
            問題が解決しない場合は、サポートにお問い合わせください。
          </TypographyCustom>
        </WrapperContent>
        <WrapperButton>
          <ButtonCustom onClick={handleClickBackPage} variant="contained" color="primary">
            戻 る
          </ButtonCustom>
        </WrapperButton>
      </WrapperBox>
    </Container>
  );
};

export default ErrorPage;
