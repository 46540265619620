/**
 * useRegisterTem hook
 */

// React
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Constants
import { HTTP_CODE, STORAGE_KEYS, NOTIFICATION_MESSAGES } from 'utils/constants';

// Models
import { EmailConfirmationRequest } from 'models/EmailConfirmationRequest';

// Libraries
import { Control, FieldErrors, UseFormHandleSubmit, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// Recoil
import { isLoading } from 'recoil/LoadingRecoil';

// Router
import ROUTES from 'configs/route';

// Services
import AuthService from 'services/AuthService';

// Utils
import { createEmailConfirmSchema, createEmailSchema } from 'utils/validation';

interface UseRegisterTemReturnType {
  control: Control<EmailConfirmationRequest, any>;
  handleSubmit: UseFormHandleSubmit<EmailConfirmationRequest, undefined>;
  errors: FieldErrors<EmailConfirmationRequest>;
  onSubmit: (_: EmailConfirmationRequest) => Promise<void>;
  handleBackPreviousPage: () => void;
  handleBackFormRegister: () => void;
  isRegisterEmailSuccess: boolean;
  expiredTime: number;
  errorMessage: string | undefined;
}

const schema = yup
  .object({
    email: createEmailSchema(),
    email_confirmation: createEmailConfirmSchema(),
  })
  .required();

export const useRegisterTem = (): UseRegisterTemReturnType => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<EmailConfirmationRequest>({
    defaultValues: { email: '', email_confirmation: '' },
    resolver: yupResolver(schema),
  });

  const [isRegisterEmailSuccess, setRegisterEmailSuccess] = useState(false);
  const [expiredTime, setExpiredTime] = useState(0);
  const setIsLoading = useSetRecoilState(isLoading);

  const navigate = useNavigate();
  const [errorMessage, setErrorsMessage] = useState<string>();
  const registerTem = async (data: EmailConfirmationRequest) => {
    try {
      const result = await AuthService.registerTem(data);
      setRegisterEmailSuccess(true);
      setExpiredTime(result?.results?.expired_time);
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data: EmailConfirmationRequest) => {
    setErrorsMessage('');
    try {
      setIsLoading(true);
      await AuthService.checkEmailExists({ email: data?.email });

      setErrorsMessage(NOTIFICATION_MESSAGES.EMAIL.ERROR.ALREADY_EXISTS);
    } catch (error: any) {
      if (error?.response?.status === HTTP_CODE.NOT_FOUND) {
        await registerTem(data);
      } else {
        setErrorsMessage(NOTIFICATION_MESSAGES.GENERAL.ERROR.SYSTEM);
      }
    }
    setIsLoading(false);
  };

  const handleBackFormRegister = () => {
    setRegisterEmailSuccess(false);
  };

  const handleBackPreviousPage = () => {
    navigate(ROUTES.LOGIN);
  };

  useEffect(() => {
    localStorage.removeItem(STORAGE_KEYS.USER_REGISTER);
  }, []);

  return {
    control,
    handleSubmit,
    errors,
    isRegisterEmailSuccess,
    handleBackFormRegister,
    handleBackPreviousPage,
    onSubmit,
    expiredTime,
    errorMessage,
  };
};
