/* eslint-disable react/no-children-prop */
/**
 * App router
 */

// React
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Pages
import NotFoundPage from 'pages/404';
import LoginPage from 'pages/Login';
import Home from 'pages/Home';
import RegisterSystem from 'pages/RegisterSystem';
import Dashboard from 'pages/Dashboard';
import Register from 'pages/Register';
import RegisterCompleted from 'pages/RegisterCompleted';
import RegisterTemporary from 'pages/RegisterTemporary';
import NewPassword from 'pages/NewPassword';
import ResetPassword from 'pages/ResetPassword';
import UserInfoPage from 'pages/UserInfo';
import ChangeEmailConfirm from 'pages/ChangeEmailConfirm';
import ChangeEmailCompleted from 'pages/ChangeEmailCompleted';
import ChangeEmailVerify from 'pages/ChangeEmailVerify';
import ChangePasswordCompleted from 'pages/ChangePasswordCompleted';
import ErrorPage from 'pages/Error';
import Logout from 'pages/Logout';
import VerifyCodeExpiredPage from 'pages/VerifyCodeExpired';

// Router
import ROUTES from 'configs/route';
import PublicRoute from 'app/PublicRoute';
import PrivateRoute from 'app/PrivateRoute';
import AuthRoute from 'app/AuthRoute';

const AppRouter: React.FC = () => (
  <Routes>
    <Route
      path={ROUTES.HOME}
      element={<AuthRoute children={<Home />} showLogo={false} background="#FAF9F8" />}
    />
    <Route
      path={ROUTES.LOGIN}
      element={
        <AuthRoute children={<LoginPage />} maxWidth="sm" showHeader={false} alignItems="center" />
      }
    />
    <Route
      path={ROUTES.REGISTER_DETAIL}
      element={
        <AuthRoute
          children={<Register />}
          maxWidth="sm"
          showHeader={false}
          showFooter
          alignItems="center"
        />
      }
    />
    <Route
      path={ROUTES.LOGOUT}
      element={
        <PublicRoute
          children={<Logout />}
          maxWidth="md"
          showHeader={false}
          showFooter={false}
          alignItems="center"
        />
      }
    />
    <Route
      path={ROUTES.CHANGE_EMAIL_CONFIRM}
      element={
        <PublicRoute
          children={<ChangeEmailConfirm />}
          maxWidth="md"
          showHeader={false}
          showFooter={true}
          alignItems="center"
        />
      }
    />
    <Route
      path={ROUTES.CHANGE_EMAIL_COMPLETED}
      element={
        <AuthRoute
          children={<ChangeEmailCompleted />}
          maxWidth="md"
          showHeader={false}
          showFooter={true}
          alignItems="center"
        />
      }
    />
    <Route
      path={ROUTES.CHANGE_EMAIL_VERIFY}
      element={
        <PublicRoute
          children={<ChangeEmailVerify />}
          maxWidth="md"
          showHeader={false}
          showFooter={true}
          alignItems="center"
        />
      }
    />
    <Route
      path={ROUTES.CHANGE_PASSWORD_COMPLETED}
      element={
        <PublicRoute
          children={<ChangePasswordCompleted />}
          maxWidth="md"
          showHeader={false}
          showFooter={true}
          alignItems="center"
        />
      }
    />
    <Route
      path={ROUTES.NEW_PASSWORD}
      element={
        <AuthRoute
          children={<NewPassword />}
          maxWidth="sm"
          showHeader={false}
          showFooter={true}
          alignItems="center"
        />
      }
    />
    <Route
      path={ROUTES.REGISTER_COMPLETED}
      element={
        <AuthRoute
          children={<RegisterCompleted />}
          maxWidth="sm"
          showHeader={false}
          showFooter={true}
          alignItems="center"
        />
      }
    />
    <Route
      path={ROUTES.REGISTER}
      element={
        <AuthRoute
          children={<RegisterTemporary />}
          maxWidth="sm"
          showHeader={false}
          showFooter={true}
          alignItems="center"
        />
      }
    />

    <Route
      path={ROUTES.PASSWORD_FORGOT}
      element={
        <AuthRoute
          children={<ResetPassword />}
          maxWidth="sm"
          showHeader={false}
          alignItems="center"
        />
      }
    />
    <Route
      path={ROUTES.USER_INFO}
      element={
        <PrivateRoute
          children={<UserInfoPage />}
          maxWidth="sm"
          showHeader
          showFooter
          alignItems="center"
          background="#FAF9F8"
        />
      }
    />
    <Route path={ROUTES.NOT_FOUND} element={<NotFoundPage />} />
    <Route
      path={ROUTES.ERROR}
      element={
        <PublicRoute
          children={<ErrorPage />}
          maxWidth="md"
          showHeader={false}
          showFooter={false}
          alignItems="center"
        />
      }
    />
    <Route
      path={ROUTES.VERIFY_CODE_EXPIRED}
      element={
        <PublicRoute
          children={<VerifyCodeExpiredPage />}
          maxWidth="sm"
          showHeader={false}
          showFooter
          alignItems="center"
        />
      }
    />
    <Route
      path={ROUTES.REGISTER_SYSTEM}
      element={
        <PrivateRoute
          children={<RegisterSystem />}
          maxWidth="sm"
          showHeader
          showFooter
          alignItems="center"
          background="#FAF9F8"
        />
      }
    />
    <Route
      path={ROUTES.DASHBOARD}
      element={<PrivateRoute children={<Dashboard />} background="#FAF9F8" />}
    />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default AppRouter;
