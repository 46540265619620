/**
 * Menu labels
 */

export const MENU_LABELS = {
  USER_ID: 'ログイン中',
  ACCOUNT_INFORMATION: 'アカウント情報',
  TERNS_OF_USE: 'おもたす利用規約',
  PRIVACY_POLICY: '個人情報の取り扱いについて',
  LOG_OUT: 'ログアウト',
};
