/**
 * Login page
 */

// React
import React, { FC } from 'react';

// Material
import { Box, Typography } from '@mui/material';

// Component
import AppPassword from 'components/common/AppPassword';
import RegisterButton from './btnNavigateRegister';
import AppInput from 'components/common/AppInput';
import { AppFieldErrors } from 'components/common/AppFieldErrors';

// Common styles
import { FieldContainer, FieldLabel, FormButton, FormContainer, WrapperForm } from 'styles';

// Hooks
import useLogin from './useLogin';

// Utils
import { FIELD_NAMES, FORM_BUTTON, FORM_LABELS, FORM_TOOLTIP } from 'utils/constants';

// Router
import ROUTES from 'configs/route';

// Component
const LoginPage: FC = () => {
  const { control, errors, errorMessage, handleSubmit, handleLogin, handleNavigate } = useLogin();

  const formFields = [
    {
      name: FIELD_NAMES.EMAIL,
      label: FORM_LABELS.EMAIL,
      type: 'text',
      titleTooltip: FORM_TOOLTIP.EMAIL,
    },
    {
      name: FIELD_NAMES.PASSWORD,
      label: FORM_LABELS.PASSWORD,
      titleTooltip: FORM_TOOLTIP.PASSWORD,
    },
  ];

  return (
    <>
      <WrapperForm>
        <FormContainer onSubmit={handleSubmit(handleLogin)}>
          <Box width="100%">
            <Typography variant="h2" color="primary">
              {FORM_LABELS.LOGIN}
            </Typography>
          </Box>

          {formFields.map(({ name, label, type, titleTooltip }) => (
            <FieldContainer key={name}>
              <FieldLabel>{label}</FieldLabel>
              {name === 'password' ? (
                <AppPassword
                  name={name}
                  control={control}
                  errors={errors}
                  titleTooltip={titleTooltip}
                  helperText={errors?.[name]?.message}
                  autoComplete={label}
                />
              ) : (
                <AppInput
                  name={name}
                  control={control}
                  type={type}
                  errors={errors}
                  helperText={errors?.[name]?.message}
                  titleTooltip={titleTooltip}
                  autoComplete={label}
                />
              )}

              {name === FIELD_NAMES.PASSWORD && (
                <FieldLabel
                  sx={{ fontSize: { xs: '12px', sm: '14px' }, cursor: 'pointer' }}
                  color="primary.dark"
                  onClick={() => handleNavigate(ROUTES.PASSWORD_FORGOT)}
                >
                  {FORM_BUTTON.FORGOT_PASSWORD}
                </FieldLabel>
              )}
            </FieldContainer>
          ))}

          <AppFieldErrors errorMessage={errorMessage} />

          <FormButton variant="contained" type="submit">
            {FORM_BUTTON.LOGIN}
          </FormButton>
        </FormContainer>
      </WrapperForm>
      <RegisterButton />
    </>
  );
};

export default LoginPage;
