/**
 * Loading recoil
 */

// Recoil
import { atom, selector } from 'recoil';

export const initIsLoading = atom({
  key: 'initIsLoading',
  default: false,
});

export const isLoading = selector({
  key: 'isLoading',
  get: ({ get }) => {
    const isLoading = get(initIsLoading);
    return isLoading;
  },
  set: ({ set }, newValue) => {
    set(initIsLoading, newValue);
  },
});
