/**
 * User info page
 */

// React
import React, { FC } from 'react';

// MUI imports
import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Service
import { WrapperForm } from 'styles/commonStyles';
import UserInfo from 'components/feature/UserInfo';
import { useUserInfo } from './useUserInfo';
import ModalConfirmPassword from './component/ModalConfirmPassword';
import { AppFieldErrors } from 'components/common/AppFieldErrors';

// Styled
const WrapperUserInfoPage = styled(Container)(({ theme }) => ({
  '& .form-user-info': {
    background: theme.palette.background.paper,
  },
}));

const BoxHeader = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: 50,
  [theme.breakpoints.down('sm')]: {
    marginBottom: 40,
  },
}));

const WrapperButton = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  marginTop: 60,
  maxWidth: 360,
  width: '100%',
  '& > *:not(:first-of-type)': {
    marginTop: 20,
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: 270,
  },
}));

// Type
export type typeName =
  | 'email'
  | 'password'
  | 'company_name'
  | 'department'
  | 'name'
  | 'name_kana'
  | 'address'
  | 'company_phone_number'
  | 'phone_number'
  | 'password_verified'
  | '';

// Interface
export interface UserInfoPageProps {
  label: string;
  value: string | JSX.Element | boolean;
  isRequired?: boolean;
  name: typeName;
}

export interface DataModalProps {
  open: boolean;
  nameVerified: typeName;
  nameUnverified: typeName;
  isVerified: boolean;
}

// Component
const UserInfoPage: FC = () => {
  const {
    handleSubmit,
    onSubmit,
    control,
    errors,
    handleClickBackPage,
    isDirty,
    setError,
    clearErrors,
    setValue,
    openModal,
    setOpenModal,
    updateUser,
    errorMessage,
  } = useUserInfo();

  return (
    <WrapperUserInfoPage>
      <form onSubmit={handleSubmit(onSubmit)}>
        <WrapperForm className="form-user-info">
          <BoxHeader>
            <Typography variant="h2" color="primary">
              アカウント情報
            </Typography>
          </BoxHeader>

          <UserInfo
            control={control}
            errors={errors}
            setError={setError}
            clearErrors={clearErrors}
            setValue={setValue}
            disableCompanyName
            isUpdateForm
          />
        </WrapperForm>

        <Box mt={{ xs: 3, sm: 5 }}>
          <AppFieldErrors errorMessage={errorMessage} />
        </Box>

        <WrapperButton>
          <Button
            fullWidth
            type="button"
            variant="outlined"
            color="secondary"
            onClick={handleClickBackPage}
          >
            戻る
          </Button>

          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="secondary"
            disabled={!isDirty || Object.keys(errors).length > 0}
          >
            アカウント情報を更新
          </Button>
        </WrapperButton>
      </form>

      <ModalConfirmPassword
        open={openModal}
        closeModal={() => setOpenModal(false)}
        updateUser={(password) => updateUser(password)}
      />
    </WrapperUserInfoPage>
  );
};

export default UserInfoPage;
