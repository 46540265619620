/**
 * App layout
 */

// React
import React from 'react';

// Components
import Footer from 'components/Footer';
import Loading from 'components/Loading';
import SnackNotify from 'components/SnackNotify';
import Header from 'components/Header';

// Common styles
import { ContainerCustom, ContentLayout, LayoutContainer } from 'styles';

// Utils
import { CombinedRouteProps } from 'utils/types';

// Component
const AppLayout: React.FC<CombinedRouteProps> = ({
  children,
  showHeader = true,
  showFooter = true,
  maxWidth,
  alignItems = 'start',
  background = '#FFFFFF',
}) => {
  return (
    <LayoutContainer
      sx={{
        background: background,
      }}
    >
      {showHeader && <Header />}
      <ContentLayout
        sx={{
          alignItems: alignItems,
        }}
      >
        <ContainerCustom
          maxWidth={maxWidth ? maxWidth : false}
          sx={{
            paddingX: maxWidth ? { xs: '20px', sm: '20px', md: '0px' } : '0px !important',
          }}
        >
          <SnackNotify />
          <Loading />
          {children}
        </ContainerCustom>
      </ContentLayout>
      {showFooter && <Footer />}
    </LayoutContainer>
  );
};

export default AppLayout;
