import React, { FC, ReactNode } from 'react';
import { Checkbox, CheckboxProps, FormControlLabel, Typography } from '@mui/material';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';

interface AppCheckboxProps extends CheckboxProps {
  name?: string;
  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
  label?: string | React.ReactNode;
}

export const AppCheckbox: FC<AppCheckboxProps> = (props) => {
  const { label, icon, checkedIcon, ...rest } = props;

  const renderLabel = (): ReactNode => {
    if (typeof label === 'string') {
      return <Typography fontWeight={600}>{label}</Typography>;
    }

    return label;
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={icon || <RadioButtonUncheckedRoundedIcon />}
          checkedIcon={checkedIcon || <CheckCircleRoundedIcon />}
          {...rest}
        />
      }
      label={renderLabel()}
    />
  );
};
