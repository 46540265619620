/**
 * useBeforeUnload hook
 */

// React
import React from 'react';

export const useBeforeUnload = (
  callback: (_event: BeforeUnloadEvent) => void,
  enable = false
): any => {
  React.useEffect(() => {
    if (enable) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        callback(event);
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [enable, callback]);
};
