/**
 * ChangePasswordCompleted page
 */

// React
import React from 'react';

// Libraries
import { useNavigate } from 'react-router-dom';

// MUI imports
import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Component
import AppLogo from 'components/AppLogo';

// Router
import ROUTES from 'configs/route';

// Styled
const ContainerCustom = styled(Container)({});

const LogoContainer = styled('div')({
  padding: '0 10px 30px 10px',
});

const WrapperForm = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '30px',
}));

const WrapperContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
});

const ButtonCustom = styled(Button)({
  width: '40%',
  padding: '10px',
  border: 'none',
  borderRadius: 0,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  maxWidth: '220px',
});

const TypographyCustom = styled(Typography)({
  fontWeight: '600',
  marginBottom: '0',
  paddingBottom: '4px',
});

const WrapperButton = styled(Box)({
  width: '100%',
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '10px',
});

// Component
const ChangePasswordCompleted: React.FC = () => {
  const navigate = useNavigate();

  const handleClickBackLogin = () => {
    navigate(ROUTES.LOGIN);
  };

  return (
    <ContainerCustom>
      <WrapperForm>
        <LogoContainer>
          <AppLogo />
        </LogoContainer>
        <WrapperContent>
          <TypographyCustom variant="h3">パスワードを変更しました</TypographyCustom>
          <TypographyCustom variant="h4">
            XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
          </TypographyCustom>
          <TypographyCustom variant="h4">
            XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
          </TypographyCustom>
        </WrapperContent>
        <WrapperButton>
          <ButtonCustom onClick={() => handleClickBackLogin()} variant="contained" color="primary">
            ログインページへ
          </ButtonCustom>
        </WrapperButton>
      </WrapperForm>
    </ContainerCustom>
  );
};

export default ChangePasswordCompleted;
