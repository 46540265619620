/**
 * Render error message password
 */

// React
import React from 'react';

// Libraries
import { FieldErrors } from 'react-hook-form';

// Material
import { Typography } from '@mui/material';

// Icon
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// Type
import { FormData } from 'pages/Register';
import { VALIDATION_MESSAGES } from 'utils/constants';

// Custom validation
const { PASSWORD } = VALIDATION_MESSAGES;

export const renderErrorMessagePassword = (errors: FieldErrors<FormData>): React.ReactNode => {
  if (!errors?.password?.message) return '';

  return errors.password.message.split(',  ').map((err: string, index: number) => (
    <Typography
      component="span"
      sx={{
        display: 'flex',
        fontSize: '12px',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '5px',
        color: '#d32f2f',
      }}
      key={index}
    >
      {err !== PASSWORD.REQUIRED && <FiberManualRecordIcon sx={{ fontSize: 10 }} />}
      <Typography component="span" sx={{ fontSize: '12px' }}>
        {err}
      </Typography>
    </Typography>
  ));
};
