/**
 * Form labels
 */

export const FORM_LABELS = {
  EMAIL: 'メールアドレス',
  PASSWORD: 'パスワード',
  LOGIN: 'ログイン',
};

export const FORM_PLACEHOLDER = {
  EMAIL_REGISTER_TEMPORARY: '登録用メールアドレス',
  EMAIL_REGISTER_TEMPORARY_CONFIRMATION: '確認のためもう一度入力してください',
  NEW_PASSWORD: '新規パスワード',
  PASSWORD_CONFIRMATION: '確認のためもう一度入力してください',
};

export const FORM_BUTTON = {
  FORGOT_PASSWORD: 'パスワードをお忘れの方',
  NEW_REGISTER: '新規登録はこちら',
  LOGIN: 'ログインする',
  REGISTER_SERVICE: '利用登録',
  CURRENTLY_APPLYING: '申請中',
  START_UP: '起動',
  SEND: '送信',
  BACK: '戻る',
};

export const FORM_RESET_PASSWORD_TITLE_HEADER = {
  HEADING: 'パスワードの再設定',
  DESCRIPTIONS: [
    '当サイトで登録されているメールアドレスを入力してください。',
    'パスワード再設定用URLをお送りいたします。',
  ],
};

export const FORM_RESET_PASSWORD_TITLE_HEADER_SUCCESS = {
  HEADING: 'メールを送信しました',
  DESCRIPTIONS: [
    '送信されたメールアドレスに再設定用URLをお送りいたしました。',
    'URLにアクセスしてパスワードを再設定してください',
  ],
};

export const FORM_REGISTER_TEMPORARY_TITLE_HEADER = {
  HEADING: 'アカウント仮登録',
  DESCRIPTIONS: [
    '当サイトをご利用になるメールアドレスを入力してください。',
    '入力されたメールアドレスに本会員登録用URLをお送りいたします。',
  ],
};

export const FORM_REGISTER_TEMPORARY_TITLE_HEADER_SUCCESS = {
  HEADING: 'アカウント仮登録を受付しました',
  DESCRIPTIONS: [
    '送信されたメールアドレスに本会員登録用URLをお送りいたしました。',
    'URLにアクセスして本登録を完了してください。',
    'URLの有効期限は${0}です。',
  ],
};

export const FORM_RESET_PASSWORD_TITLE_HEADER_2 = {
  HEADING: 'パスワードの再設定',
};

export const FORM_CHANGE_PASSWORD_SUCCESS = {
  HEADING: 'パスワードを変更しました',
};
