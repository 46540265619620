/**
 * App container
 */

// React
import React, { useState, Dispatch, SetStateAction, useMemo } from 'react';

// Define the type for the AppContext
type AppContextType = {
  isLogin: boolean;
  setIsLogin: Dispatch<SetStateAction<boolean>>;
  accessToken?: string;
  setAccessToken?: Dispatch<SetStateAction<string | undefined>>;
};

// Define the type for the component's props
interface AppContainerProps {
  children: React.ReactNode;
}

export const AppContext = React.createContext<AppContextType>({
  isLogin: false,
  setIsLogin: () => {},
  accessToken: undefined,
  setAccessToken: () => {},
});

const AppContainer: React.FC<AppContainerProps> = ({ children }) => {
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  const contextValue: AppContextType = useMemo(
    () => ({
      isLogin,
      setIsLogin,
      accessToken,
      setAccessToken,
    }),
    [isLogin, setIsLogin, accessToken, setAccessToken]
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export default AppContainer;
