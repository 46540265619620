/**
 * Modal confirm password component
 */

// React
import React from 'react';

// Libraries
import { Box, Button, Dialog, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

// Components
import AppPassword from 'components/common/AppPassword';

// recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isLoading } from 'recoil/LoadingRecoil';
import { userInfoAtom } from 'recoil/UserInfoRecoil';

// utils
import { HTTP_CODE, RESPONSE_CODE, VALIDATION_MESSAGES } from 'utils/constants';

// Service
import AuthService from 'services/AuthService';

// Styled
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: '0px',
    padding: '65px 70px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px',
    },
  },
}));

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: 50,
});

// Interface
export interface ModalEditUserProps {
  open: boolean;
  closeModal: () => void;
  updateUser: (_: string) => void;
}

export type FormData = {
  password: string;
};

const { PASSWORD } = VALIDATION_MESSAGES;

const schema = yup
  .object({
    password: yup.string().required(PASSWORD.REQUIRED),
  })
  .required();

// Component
const ModalConfirmPassword: React.FC<ModalEditUserProps> = ({ open, closeModal, updateUser }) => {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(schema),
  });
  const setIsLoading = useSetRecoilState(isLoading);
  const userInfo = useRecoilValue(userInfoAtom);

  const onSubmit = async (data: FormData) => {
    try {
      setIsLoading(true);
      await AuthService.checkPassword({
        email: userInfo?.email || '',
        password: data?.password || '',
      });
      updateUser(data?.password);
      reset();
    } catch (error: any) {
      const responseMessage = error?.response?.data?.results?.error_message;
      const responseCode = error?.response?.data?.results?.error_code;
      const responseStatus = error?.response?.status;
      if (
        responseStatus === HTTP_CODE.UNPROCESSABLE &&
        responseCode === RESPONSE_CODE.NOT_AUTHORIZED
      ) {
        setError('password', { message: responseMessage });
      }
      error?.status === HTTP_CODE.SERVER_ERROR
        ? setError('password', { message: 'システムエラー' })
        : setError('password', { message: 'パスワードが正しくありません。' });
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <StyledDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h2" color="primary">
          本人確認
        </Typography>
        <Typography>本人確認のためパスワードを入力してください。</Typography>

        <Box>
          <Typography mb={1} fontWeight="700">
            パスワード
          </Typography>
          <AppPassword
            name="password"
            control={control}
            errors={errors}
            helperText={errors?.password?.message}
            placeholder="確認のためもう一度入力してください"
          />
        </Box>

        <Grid container columnSpacing={1}>
          <Grid size={6}>
            <Button
              fullWidth
              onClick={handleClose}
              type="button"
              variant="outlined"
              color="secondary"
            >
              キャンセル
            </Button>
          </Grid>
          <Grid size={6}>
            <Button fullWidth type="submit" variant="contained" color="secondary">
              送信
            </Button>
          </Grid>
        </Grid>
      </StyledForm>
    </StyledDialog>
  );
};

export default ModalConfirmPassword;
