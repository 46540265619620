/**
 * VerifyCodeExpired page
 */

// React
import React from 'react';

// Libraries
import { useLocation, useNavigate } from 'react-router-dom';

// MUI imports
import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Component
import AppLogo from 'components/AppLogo';

// Router
import ROUTES from 'configs/route';
import { WrapperForm } from 'styles';

// Utils
import { convertTimestampToDateTime } from 'utils/helper';
import { DATE_TIME_FORMATS } from 'utils/constants';

// Styled
const LogoContainer = styled('div')({
  padding: '0 10px 40px 10px',
});

const TypographyCustom = styled(Typography)({
  fontWeight: '600',
  marginBottom: '0',
  paddingBottom: '10px',
});

const WrapperButton = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  marginTop: 30,
  maxWidth: 260,
  height: '40px',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    maxWidth: 200,
  },
}));

// Component
const VerifyCodeExpiredPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const expiredTime = location.state?.expiredTime;
  const expiredTimeWithFormat = expiredTime
    ? convertTimestampToDateTime(+expiredTime, DATE_TIME_FORMATS.JAPANESE)
    : '〜〜〜';

  const handleClickRegisterTemPage = async () => {
    try {
      navigate(ROUTES.REGISTER);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <LogoContainer>
        <AppLogo />
      </LogoContainer>
      <WrapperForm>
        <Box paddingBottom="30px">
          <TypographyCustom textAlign="center" variant="h2">
            このURLは無効または期限切れです
          </TypographyCustom>
        </Box>

        <TypographyCustom variant="h4" textAlign="center">
          恐れ入りますが、もう一度アカウント登録手続きを行ってください。
        </TypographyCustom>
        <TypographyCustom variant="h4" textAlign="center">
          URLの有効期限は{expiredTimeWithFormat}です。
        </TypographyCustom>

        <WrapperButton>
          <Button
            onClick={handleClickRegisterTemPage}
            variant="contained"
            fullWidth
            color="secondary"
          >
            新規登録はこちら
          </Button>
        </WrapperButton>
      </WrapperForm>
    </Container>
  );
};

export default VerifyCodeExpiredPage;
