/**
 * Form tooltip
 */

export const FORM_TOOLTIP = {
  EMAIL: '登録したメールアドレスを入力してください',
  EMAIL_CONFIRM: '同じメールアドレスを入力してください',
  PASSWORD_CONFIRM: 'パスワードが合致しませんでした',
  PASSWORD: 'パスワードの文字数は10文字以上です',
  PASSWORD_UPDATE_USER: 'パスワード変更時、以前のパスワードが必要となります。',
  NEW_PASSWORD: 'パスワードが合致しませんでした',
  PHONE_NUMBER: '数字（ハイフンなし）で入力してください',
  COMPANY_PHONE_NUMBER: '数字（ハイフンなし）で入力してください',
  ZIP_CODE: '数字7桁でご入力ください',
};
