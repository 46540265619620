// React
import { useEffect, useState } from 'react';

// Libraries
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

// Recoil
import { DataUserInfoAtom, userInfoAtom } from 'recoil/UserInfoRecoil';
import { isLoading } from 'recoil/LoadingRecoil';
import { snackNotifyAtom } from 'recoil/SnackNotifyRecoil';

// Utils
import { ServiceSystem } from 'utils/types/serviceType';
import { NOTIFICATION_MESSAGES, STATUS_SERVICE_SYSTEM, STORAGE_KEYS } from 'utils/constants';
import { UserService } from 'utils/types';
import { getErrorsMessage } from 'utils/helper';

// Service
import AuthService from 'services/AuthService';

// Configs
import ROUTES from 'configs/route';

interface UseRegisterSystemReturnTypes {
  userInfo: DataUserInfoAtom;
  statusServiceSystemRequested: boolean;
  handleSubmit: (_: any) => Promise<void>;
  serviceSystemSelected: ServiceSystem;
  handleChangeDescription: (_: React.ChangeEvent<HTMLTextAreaElement>) => void;
  description: string;
  handleBackDashboardPage: () => void;
  errorMessage: string[] | null;
}

export const useRegisterSystem = (): UseRegisterSystemReturnTypes => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const setIsLoading = useSetRecoilState(isLoading);
  const [description, setDescription] = useState('');
  const [statusServiceSystemRequested, setStatusServiceSystemRequested] = useState(false);
  const [errorMessage, setErrorsMessage] = useState<string[] | null>(null);

  const setSnackNotify = useSetRecoilState(snackNotifyAtom);

  const serviceSystemSelected: ServiceSystem = JSON.parse(
    localStorage?.getItem(STORAGE_KEYS.SERVICE_SYSTEM_SELECTED) || '{}'
  );

  const updateServiceDescriptionAndStatus = (services?: UserService[]) => {
    const userService = services?.find(
      (registeredService) => registeredService?.id === serviceSystemSelected?.id
    );

    const newDescription = userService?.description || '';
    const statusRequested = userService?.status === STATUS_SERVICE_SYSTEM.REQUESTED;

    setDescription(newDescription);
    setStatusServiceSystemRequested(statusRequested);
  };

  const initData = async () => {
    if (!serviceSystemSelected?.id) {
      handleBackDashboardPage();
    } else {
      updateServiceDescriptionAndStatus(userInfo?.services);
    }
  };

  const handleSubmit = async (e: any) => {
    setErrorsMessage(null);
    e.preventDefault();
    if (!userInfo?.id) return;

    try {
      setIsLoading(true);
      await AuthService.registerService({
        service_id: serviceSystemSelected?.id,
        description: description,
      });

      const user = await AuthService.getUserInfo();
      updateServiceDescriptionAndStatus(user?.services);
      setUserInfo({ ...user });

      setSnackNotify({
        open: true,
        title: NOTIFICATION_MESSAGES.REGISTER.SUCCESS,
      });
      navigate(ROUTES.DASHBOARD);
    } catch (error: any) {
      setSnackNotify({
        open: true,
        severity: 'error',
        title: NOTIFICATION_MESSAGES.REGISTER.ERROR,
      });
      const errorsMessage = getErrorsMessage(error);
      setErrorsMessage(errorsMessage);
    }
    setIsLoading(false);
  };

  const handleChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleBackDashboardPage = () => {
    navigate(ROUTES.DASHBOARD);
  };

  useEffect(() => {
    initData();
  }, []);

  return {
    userInfo,
    statusServiceSystemRequested,
    handleSubmit,
    serviceSystemSelected,
    handleChangeDescription,
    description,
    handleBackDashboardPage,
    errorMessage,
  };
};
