/**
 * App input component
 */

// React
import React from 'react';

// Library
import { Controller, Control, FieldErrors, FieldValues, Path } from 'react-hook-form';

// Material
import { TextFieldProps } from '@mui/material';

// Component
import TooltipInput from '../TooltipInput';

// Common styles
import { CustomTextField } from 'styles';

// Define a generic type T for form data, constrained to FieldValues
type AppInputProps<T extends FieldValues> = TextFieldProps & {
  name: Path<T>;
  control: Control<T>;
  type?: string;
  errors?: FieldErrors<T>;
  titleTooltip?: string;
  validatePhone?: boolean;
};

const handlePaste = (e: any) => {
  const pastedData = e.clipboardData.getData('Text');
  if (!/^\d+$/.test(pastedData)) {
    e.preventDefault();
  }
};

const handleKeyPress = (e: any) => {
  if (!/\d/.test(e.key)) {
    e.preventDefault();
  }
};

// Component
const AppInput = <T extends FieldValues>({
  name,
  control,
  type,
  errors,
  titleTooltip,
  validatePhone = false,
  ...rest
}: AppInputProps<T>): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...restField } }) => (
        <TooltipInput titleTooltip={titleTooltip}>
          <CustomTextField
            {...restField}
            inputRef={ref}
            value={restField?.value}
            fullWidth
            type={type}
            onKeyPress={validatePhone ? handleKeyPress : undefined}
            onPaste={validatePhone ? handlePaste : undefined}
            error={!!errors?.[name]}
            {...rest}
          />
        </TooltipInput>
      )}
    />
  );
};

export default AppInput;
