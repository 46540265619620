/**
 * Constants
 */

export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'access_token',
  BANNERS: 'banners',
  USER_REGISTER: 'user_register',
  REFRESH_TOKEN: 'refresh_token',
  PREVIOUS_PAGE_ADDRESS: 'previous_page_address',
  SERVICE_SYSTEM_SELECTED: 'service_system_selected',
  SERVICE_SYSTEMS: 'service_systems',
  IS_LIMIT_EXCEEDED: 'is_limit_exceeded',
};

export const HTTP_CODE = {
  OK: 200,
  BAD_REQUEST: 400,
  UNPROCESSABLE: 422,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
};

export const HTTP_METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const STATUS_SERVICE_SYSTEM = {
  REQUESTED: 'requested',
  IN_USED: 'in-used',
  CANCELED: 'canceled',
  FINISHED: 'finished',
};

export const RESPONSE_CODE = {
  EMAIL_DOES_NOT_EXIST: 'email_does_not_exist',
  NOT_AUTHORIZED: 'cognito_error.NotAuthorizedException',
  LIMIT_EXCEEDED: 'cognito_error.LimitExceededException',
  CONFIRM_CODE_EXPIRED: 'cognito_error.ExpiredCodeException',
  REUSE_PASSWORD: 'cognito_error.PasswordHistoryPolicyViolationException',
  USER_LOCKED: 'user_locked',
};

export const PASSWORD_POLICY = {
  MIN_LENGTH: 10,
  MAX_LENGTH: 32,
  STRONG_LENGTH: 12,
};

export const EMAIL_POLICY = {
  MAX_LENGTH: 50,
};

export const PHONE_NUMBER_POLICY = {
  PHONE_NUMBER_MIN_LENGTH: 10,
};

export const ZIPCODE_POLICY = {
  MIN_LENGTH: 7,
};

export type Strength = 1 | 2 | 3 | 4;

export const PASSWORD_STRENGTH: { [key: string]: Strength } = {
  FULL_STRENGTH: 4,
  WEAK_STRENGTH: 2,
  MEDIUM_STRENGTH: 3,
};

export const URL_PRIVACY_TERM = {
  PRIVACY_POLICY_URL: process.env.REACT_APP_PRIVACY_POLICY_URL || '#',
  TERM_OF_USE_URL: process.env.REACT_APP_TERM_OF_USE_URL || '#',
};

export const TIME_REFRESH_TOKEN = 180;

export const TIME_LOCKED = 5000;

export const DATE_TIME_FORMATS = {
  JAPANESE: 'yyyy年MM月dd日 HH時mm分ss秒',
};

export const URL_GET_ADDRESS = {
  ADDRESS_URL: process.env.REACT_APP_ADDRESS_URL || '',
  ADDRESS_API_KEY: process.env.REACT_APP_ADDRESS_API_KEY || '',
};

export const AWS_S3 = {
  BASE_URL: process.env.REACT_APP_S3_URL,
  LOGO_PNG: `${process.env.REACT_APP_S3_URL}/system/logo.png`,
  LOGO_SVG: `${process.env.REACT_APP_S3_URL}/system/logo.svg`,
};

export const PARAMS_GENERATE_AUTH_CODE = {
  REDIRECT_URI: process.env.REACT_APP_AF_ADMIN_REDIRECT_URI || '',
  CLIENT_ID: process.env.REACT_APP_AF_ADMIN_CLIENT_ID || '',
};

export const ROLE_USER = {
  AF_USER: 'af_user',
  COMPANY_USER: 'company_user',
};
