/**
 * Register Completed page
 */

// React
import React from 'react';

// Libraries
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

// MUI imports
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Hooks
import useLocalStorage from 'hooks/useLocalStorage';

// Utils
import { STORAGE_KEYS } from 'utils/constants';

// Services
import AuthService from 'services/AuthService';

// Recoil
import { isLoading } from 'recoil/LoadingRecoil';

// Router
import ROUTES from 'configs/route';

// Type
import { FormData } from 'pages/Register';

// // Common Styles
import { WrapperForm } from 'styles/commonStyles';

// Styled
const RegisterCompletedContainer = styled(Box)({
  margin: '0 auto',
});

const TypographyCustom = styled(Typography)({
  fontWeight: '600',
});

const WrapperButton = styled(Box)(({ theme }) => ({
  margin: '0 auto',
  marginTop: 30,
  maxWidth: 260,
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    maxWidth: 200,
  },
}));

// Component Completed
const RegisterCompletedPage: React.FC = () => {
  const navigate = useNavigate();

  const [, setAccessToken] = useLocalStorage(STORAGE_KEYS.ACCESS_TOKEN);
  const [, setRefreshToken] = useLocalStorage(STORAGE_KEYS.REFRESH_TOKEN);
  const [dataUser] = useLocalStorage<FormData>(STORAGE_KEYS.USER_REGISTER);
  const setIsLoading = useSetRecoilState(isLoading);

  const handleLogin = async () => {
    try {
      if (!dataUser?.email) return;
      setIsLoading(true);
      const result = await AuthService.login({
        email: dataUser?.email,
        password: dataUser.password,
      });
      localStorage.removeItem(STORAGE_KEYS.USER_REGISTER);
      setAccessToken(result.access_token);
      setRefreshToken(result.refresh_token);
      setIsLoading(false);
      navigate(ROUTES.DASHBOARD);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <RegisterCompletedContainer>
      <WrapperForm>
        <TypographyCustom textAlign="center" variant="h2" mb={5}>
          アカウントを登録しました
        </TypographyCustom>

        <WrapperButton>
          <Button onClick={handleLogin} variant="contained" fullWidth>
            ダッシュボードへ
          </Button>
        </WrapperButton>
      </WrapperForm>
    </RegisterCompletedContainer>
  );
};

export default RegisterCompletedPage;
