/**
 * SnackNotify component
 */

// React
import React, { FC } from 'react';

// Library
import { useRecoilValue, useSetRecoilState } from 'recoil';

// Material
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// Recoil
import { snackNotifyAtom } from 'recoil/SnackNotifyRecoil';

const SnackNotify: FC = () => {
  const {
    open,
    autoHideDuration = 3000,
    severity = 'success',
    title,
  } = useRecoilValue(snackNotifyAtom);
  const setSnackNotify = useSetRecoilState(snackNotifyAtom);

  const handleClose = () => {
    setSnackNotify((prev) => ({ ...prev, open: false }));
  };

  return (
    <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
        {title}
      </Alert>
    </Snackbar>
  );
};

export default SnackNotify;
