/**
 * Theme configuration
 */

import { createTheme } from '@mui/material/styles';
import { PaletteOptions, TypographyVariantsOptions, Components, Theme } from '@mui/material';

const theme = createTheme();

// 1. Define the palette options
const lightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#6EC8E2',
    dark: '#2E9BB9',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#000000',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#FFE33F',
  },
  background: {
    default: '#FAF9F8',
    paper: '#FFFFFF',
  },
  divider: '#E2F4F9',
  text: {
    secondary: '#bbbbbb',
  },
  grey: {
    '50': '#dddddd',
  },
  error: {
    main: '#FF1000',
  },
};

const darkPalette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#90CAF9',
  },
  secondary: {
    main: '#CE93D8',
  },
  background: {
    default: '#121212',
    paper: '#1D1D1D',
  },
};

// 2. Define the typography options
const typography: TypographyVariantsOptions = {
  fontFamily: '"Noto Sans JP", sans-serif',
  h1: {
    fontSize: '2rem',
    fontWeight: 600,
  },
  h2: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
  body1: {
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  h3: {
    fontSize: '1.0rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  h4: {
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  h5: {
    fontSize: '13px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },
};

// 3. Define the component overrides
const components: Components<Theme> = {
  MuiContainer: {
    styleOverrides: {
      maxWidthSm: {
        maxWidth: '720px',
        '@media (min-width:600px)': {
          maxWidth: '720px',
        },
        padding: '0 30px',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 30,
        textTransform: 'none',
        height: 40,
        boxShadow: 'none',
        marginTop: 0,
        fontSize: '18px',
        fontWeight: '600',
        [theme.breakpoints.down('sm')]: {
          fontSize: '15px',
        },
      },
      outlined: {
        border: '2px solid #DDDDDD',
        backgroundColor: '#FFFFFF !important',
        color: 'black',
      },
      outlinedSecondary: {
        borderColor: '#DDDDDD',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        padding: '16px',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& input': {
          fontSize: '1rem',
          padding: '5px 0px',
          [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: 5,
          border: '2px solid #DDDDDD',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: '2px solid #DDDDDD',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #5abdd8',
          transition: 'all 0.3s ease',
        },
        '&.Mui-disabled': {
          backgroundColor: '#FAF9F8',
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          border: '2px solid #DDDDDD',
        },
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        fontSizeSmall: {
          fontSize: '0.6rem',
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        maxWidth: 'unset',
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        color: 'black',
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: '#DDDDDD',
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        margin: '3px 0 0 0 !important',
      },
    },
  },
};

// 4. Create the theme
export const lightTheme = createTheme({
  palette: lightPalette,
  typography,
  components,
  spacing: 6,
});

export const darkTheme = createTheme({
  palette: darkPalette,
  typography,
  components,
});
