// React
import React, { FC, useRef, useState } from 'react';

// MUI
import { CircularProgress, InputAdornment, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

// Models
import { FormRegister } from 'models/RegisterRequest';

// Components
import TooltipInput from 'components/common/TooltipInput';

// // Libraries
import { Controller } from 'react-hook-form';

// Common Styles
import { CustomTextField } from 'styles/commonStyles';

// Services
import AuthService from 'services/AuthService';

// Utils
import { debouncer } from 'utils/helper';
import { ReactHookFormType } from 'utils/types';

interface ZipcodeProps extends ReactHookFormType<FormRegister> {
  disable: boolean;
  titleTooltip?: string;
}

export const Zipcode: FC<ZipcodeProps> = ({ control, errors, titleTooltip, setValue, disable }) => {
  const [loading, setLoading] = useState(false);
  const debouncedSearchRef = useRef(debouncer());
  const timeoutSearchZipcode = 1000;

  const handleGetAddress = async (zipcode: string) => {
    try {
      setLoading(true);
      const res = await AuthService.getAddress({ zip_code: zipcode });
      const { city = '', pref = '', town = '' } = res?.[0] || {};

      setValue('city', city, { shouldValidate: true });
      setValue('prefecture', pref, { shouldValidate: true });
      setValue('address', town, { shouldValidate: true });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleSearch = (zipcode: string) => {
    debouncedSearchRef.current(() => {
      if (!errors?.zipcode?.message) {
        handleGetAddress(zipcode);
      }
    }, timeoutSearchZipcode);
  };

  return (
    <Grid container size={12} spacing={1}>
      <Typography fontWeight={600}>住所</Typography>
      <Grid container size={12} spacing={1}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Controller
            name="zipcode"
            control={control}
            render={({ field }) => (
              <TooltipInput titleTooltip={titleTooltip}>
                <CustomTextField
                  {...field}
                  value={field.value || ''}
                  onChange={(e: any) => {
                    const newValue = e.target.value;
                    field.onChange(e);
                    handleSearch(newValue);
                  }}
                  placeholder="郵便番号"
                  variant="outlined"
                  fullWidth
                  error={!!errors?.zipcode}
                  helperText={errors?.zipcode?.message}
                  disabled={disable}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {loading && <CircularProgress size="15px" />}
                      </InputAdornment>
                    ),
                  }}
                />
              </TooltipInput>
            )}
          />
        </Grid>
      </Grid>

      <Grid container size={12} spacing={1}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Controller
            name="prefecture"
            control={control}
            render={({ field }) => (
              <CustomTextField
                {...field}
                value={field.value || ''}
                placeholder="都道府県"
                variant="outlined"
                fullWidth
                error={!!errors.prefecture}
                helperText={errors.prefecture?.message}
                disabled={disable}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <CustomTextField
                {...field}
                value={field.value || ''}
                placeholder="市区町村"
                variant="outlined"
                fullWidth
                error={!!errors.city}
                helperText={errors.city?.message}
                disabled={disable}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid size={12}>
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <CustomTextField
              {...field}
              value={field.value || ''}
              placeholder="番地"
              variant="outlined"
              fullWidth
              error={!!errors.address}
              helperText={errors.address?.message}
              disabled={disable}
            />
          )}
        />
      </Grid>
      <Grid size={12}>
        <Controller
          name="building"
          control={control}
          render={({ field }) => (
            <CustomTextField
              {...field}
              value={field.value || ''}
              placeholder="建物"
              variant="outlined"
              fullWidth
              error={!!errors.building}
              helperText={errors.building?.message}
              disabled={disable}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
