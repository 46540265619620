/**
 * Public route
 */

// React
import React from 'react';

// Components
import AppLayout from 'app/AppLayout';

// Utils
import { CombinedRouteProps } from 'utils/types';

const PublicRoute: React.FC<CombinedRouteProps> = ({
  children,
  showHeader = true,
  showFooter = true,
  maxWidth,
  alignItems = 'start',
}) => {
  return (
    <AppLayout
      showHeader={showHeader}
      alignItems={alignItems}
      showFooter={showFooter}
      maxWidth={maxWidth}
    >
      {children}
    </AppLayout>
  );
};

export default PublicRoute;
