/**
 * NewPassword page
 */

// React
import React, { FC } from 'react';

// Library
import { Navigate, useSearchParams } from 'react-router-dom';

// Material
import { Box } from '@mui/material';

// Utils
import {
  FIELD_NAMES,
  FORM_BUTTON,
  FORM_CHANGE_PASSWORD_SUCCESS,
  FORM_PLACEHOLDER,
  FORM_RESET_PASSWORD_TITLE_HEADER_2,
  FORM_LABELS,
} from 'utils/constants';

// Components
import AppPassword from 'components/common/AppPassword';
import { AppFieldErrors } from 'components/common/AppFieldErrors';

// Hook
import useNewPassword from './useNewPassword';

// Common styles
import {
  FieldContainer,
  FormButton,
  FormContainer,
  TypographyCustom,
  WrapperContent,
  WrapperForm,
} from 'styles';

// Router
import ROUTES from 'configs/route';

// Utils
import { renderErrorMessagePassword } from 'utils/helper';

// Form fields
const formFields = [
  {
    name: FIELD_NAMES.PASSWORD,
    placeholder: FORM_PLACEHOLDER.NEW_PASSWORD,
  },
  {
    name: FIELD_NAMES.PASSWORD_CONFIRM,
    placeholder: FORM_PLACEHOLDER.PASSWORD_CONFIRMATION,
  },
];

// Component
const NewPassword: FC = () => {
  const {
    control,
    errors,
    errorMessage,
    handleSubmit,
    onSubmit,
    changePasswordSuccess,
    handleClickBackLogin,
  } = useNewPassword();

  const [searchParams] = useSearchParams();
  const username = decodeURIComponent(searchParams.get('user_name') || '');
  const confirmCode = searchParams.get('confirmation_code') || '';
  const expiredTime = searchParams.get('expired_time') || '';

  if (!username || !confirmCode || !expiredTime) {
    return <Navigate to={ROUTES.ERROR} />;
  }

  const renderChangePasswordForm = () => (
    <WrapperForm>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <WrapperContent alignItems="start">
          <TypographyCustom variant="h2">
            {FORM_RESET_PASSWORD_TITLE_HEADER_2.HEADING}
          </TypographyCustom>
        </WrapperContent>

        {formFields.map(({ name, placeholder }) => (
          <FieldContainer key={name}>
            <Box sx={{ width: '100%' }}>
              <AppPassword
                name={name}
                control={control}
                errors={errors}
                placeholder={placeholder}
                autoComplete={name}
                passwordStrength={name === 'password'}
                helperText={name === 'password_confirm' && errors?.[name]?.message}
              />
              {name === 'password' && renderErrorMessagePassword(errors)}
            </Box>
          </FieldContainer>
        ))}

        <AppFieldErrors errorMessage={errorMessage} />

        <FormButton variant="contained" type="submit" color="secondary">
          {FORM_BUTTON.SEND}
        </FormButton>
      </FormContainer>
    </WrapperForm>
  );

  const renderChangePasswordSuccess = () => (
    <WrapperForm>
      <FormContainer>
        <WrapperContent alignItems="center">
          <TypographyCustom
            sx={{
              mb: {
                xs: '0px',
                sm: '10px',
              },
            }}
            variant="h2"
          >
            {FORM_CHANGE_PASSWORD_SUCCESS.HEADING}
          </TypographyCustom>
        </WrapperContent>

        <FormButton
          onClick={handleClickBackLogin}
          variant="contained"
          type="button"
          color="primary"
        >
          {FORM_LABELS.LOGIN}
        </FormButton>
      </FormContainer>
    </WrapperForm>
  );

  return changePasswordSuccess ? renderChangePasswordSuccess() : renderChangePasswordForm();
};

export default NewPassword;
