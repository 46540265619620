export const debouncer = (): ((_fnc: () => void, _delayMs?: number) => void) => {
  let timeout: any = null;

  return (fnc: () => void, delayMs = 600) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fnc();
    }, delayMs);
  };
};
