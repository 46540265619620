/**
 * useServicesAndBanners hook
 */

// Libraries
import { useSetRecoilState } from 'recoil';

// Recoil
import { isLoading } from 'recoil/LoadingRecoil';

// Services
import AuthService from 'services/AuthService';
import BannerService from 'services/BannerService';

// Hooks
import useLocalStorage from 'hooks/useLocalStorage';

// Utils
import { STORAGE_KEYS } from 'utils/constants';
import { ServiceSystem } from 'utils/types/serviceType';
import { BannerType } from 'utils/types';

const useServicesAndBanners = (): {
  banners: BannerType[];
  services: ServiceSystem[];
  fetchServiceAndBanners: () => Promise<void>;
} => {
  const setIsLoading = useSetRecoilState(isLoading);
  const [banners, setBanners] = useLocalStorage<BannerType[]>(STORAGE_KEYS.BANNERS);
  const [services, setServices] = useLocalStorage<ServiceSystem[]>(STORAGE_KEYS.SERVICE_SYSTEMS);

  const fetchServices = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const results = await AuthService.getServices();
      setServices(results);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBanners = async (): Promise<void> => {
    try {
      const res = await BannerService.getBanners();
      const bannersForPC = res?.pc || [];
      const bannersForSP = res?.sp || [];

      const banners: BannerType[] = bannersForPC.map((pcBanner: any) => {
        const spBanner = bannersForSP.find(
          (spBanner: any) => spBanner?.position === pcBanner?.position
        );
        return {
          desktop: pcBanner,
          mobile: spBanner,
        };
      });
      setBanners(banners);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchServiceAndBanners = async (): Promise<void> => {
    await Promise.all([fetchServices(), fetchBanners()]);
  };

  return { banners, services, fetchServiceAndBanners };
};

export default useServicesAndBanners;
