import { MAX_LENGTHS, VALIDATION_ATTRIBUTES, VALIDATION_MESSAGES } from 'utils/constants';
import * as yup from 'yup';

const { EMAIL, EMAIL_CONFIRM, PASSWORD, PASSWORD_CONFIRM, COMMON } = VALIDATION_MESSAGES;

export const createEmailSchema = (): yup.StringSchema<string, yup.AnyObject, undefined, ''> =>
  yup
    .string()
    .max(MAX_LENGTHS.MAX_50, COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.EMAIL, MAX_LENGTHS.MAX_50))
    .email(EMAIL.INVALID)
    .required(EMAIL.REQUIRED);

export const createEmailConfirmSchema = (): yup.StringSchema<string> =>
  yup
    .string()
    .required(EMAIL_CONFIRM.REQUIRED)
    .oneOf([yup.ref('email')], EMAIL_CONFIRM.MATCH);

export const createPasswordSchema = (): yup.StringSchema<string> =>
  yup
    .string()
    .max(MAX_LENGTHS.MAX_32, COMMON.MAX_LENGTH(VALIDATION_ATTRIBUTES.PASSWORD, MAX_LENGTHS.MAX_32))
    .required(PASSWORD.REQUIRED);

export const createPasswordConfirmSchema = (): yup.StringSchema<string> =>
  yup
    .string()
    .oneOf([yup.ref('password')], PASSWORD_CONFIRM.MATCH)
    .required(PASSWORD_CONFIRM.REQUIRED);
