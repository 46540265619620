/**
 * Route config
 */

const ROUTES = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  REGISTER_DETAIL: '/register/detail',
  REGISTER_COMPLETED: '/register-completed',
  REGISTER: '/register',
  PASSWORD_FORGOT: '/password/forgot',
  NEW_PASSWORD: '/password/forgot/confirm',
  CHANGE_EMAIL_CONFIRM: '/change-email-confirm',
  CHANGE_EMAIL_VERIFY: '/change-email-verify',
  CHANGE_EMAIL_COMPLETED: '/change-email-completed',
  CHANGE_PASSWORD_COMPLETED: '/change-password-completed',
  REGISTER_SYSTEM: '/register-system',
  USER_INFO: '/userinfo',
  ERROR: '/error',
  VERIFY_CODE_EXPIRED: '/verify-code-expired',
  LOGOUT: '/logout',
  NOT_FOUND: '*',
};

export default ROUTES;
