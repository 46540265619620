/**
 * Change email completed page
 */

// React
import React from 'react';

// Libraries
import { useNavigate } from 'react-router-dom';

// MUI imports
import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Component
import AppLogo from 'components/AppLogo';

// Utils
import { NOTIFICATION_MESSAGES } from 'utils/constants';

// Router
import ROUTES from 'configs/route';

// Styled
const ContainerCustom = styled(Container)({});

const LogoContainer = styled('div')({
  padding: '0 10px 40px 10px',
});

const WrapperChangeEmailForm = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '30px',
}));

const WrapperContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
});

const ButtonCustom = styled(Button)({
  width: '40%',
  padding: '10px',
  border: 'none',
  borderRadius: 0,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '10px',
  maxWidth: '220px',
});

const TypographyCustom = styled(Typography)({
  fontWeight: '600',
  marginBottom: '0',
  paddingBottom: '4px',
});

const WrapperButton = styled(Box)({
  width: '100%',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '10px',
});

// Component
const ChangeEmailCompletedPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClickBackPage = async () => {
    navigate(ROUTES.LOGIN);
  };

  return (
    <ContainerCustom>
      <WrapperChangeEmailForm>
        <LogoContainer>
          <AppLogo />
        </LogoContainer>
        <WrapperContent>
          <TypographyCustom variant="h3">
            {NOTIFICATION_MESSAGES.EMAIL.SUCCESS.CHANGE}
          </TypographyCustom>
          <TypographyCustom variant="h4">
            {NOTIFICATION_MESSAGES.EMAIL.SUCCESS.REGIN_REQUIRED}
          </TypographyCustom>
        </WrapperContent>
        <WrapperButton>
          <ButtonCustom onClick={handleClickBackPage} variant="contained" color="primary">
            戻 る
          </ButtonCustom>
        </WrapperButton>
      </WrapperChangeEmailForm>
    </ContainerCustom>
  );
};

export default ChangeEmailCompletedPage;
