/**
 * bthNavigateRegister
 */

// React
import React, { FC } from 'react';

// Library
import { useLocation, useNavigate } from 'react-router-dom';

// Material
import { Box } from '@mui/material';

// Hooks
import useLocalStorage from 'hooks/useLocalStorage';

// Common styles
import { FormButton } from 'styles';

// Router
import ROUTES from 'configs/route';

// Utils
import { FORM_BUTTON, STORAGE_KEYS } from 'utils/constants';

// Component
const RegisterButton: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [previousPageAddress, setPreviousPageAddress] = useLocalStorage(
    STORAGE_KEYS.PREVIOUS_PAGE_ADDRESS
  );

  const handleNavigate = () => {
    if (previousPageAddress !== location.pathname) {
      setPreviousPageAddress(location.pathname);
    }
    navigate(ROUTES.REGISTER);
  };

  return (
    <Box
      width="100%"
      sx={{
        marginTop: {
          xs: '40px',
          sm: '60px',
        },
        textAlign: 'center',
      }}
    >
      <FormButton onClick={handleNavigate} type="button" variant="contained" color="secondary">
        {FORM_BUTTON.NEW_REGISTER}
      </FormButton>
    </Box>
  );
};

export default RegisterButton;
