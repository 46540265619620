/**
 * useResetPassword hook
 */

// React
import { useState } from 'react';

// Libraries
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSetRecoilState } from 'recoil';
import { Control, FieldErrors, useForm, UseFormHandleSubmit } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// Recoil
import { isLoading } from 'recoil/LoadingRecoil';

// Utils
import { createEmailSchema, createEmailConfirmSchema } from 'utils/validation';
import { getErrorsMessage } from 'utils/helper';

// Type
import { EmailConfirmationRequest } from 'models/EmailConfirmationRequest';

// Router
import ROUTES from 'configs/route';

// Services
import AuthService from 'services/AuthService';

// Custom validation
const schema = yup
  .object({
    email: createEmailSchema(),
    email_confirmation: createEmailConfirmSchema(),
  })
  .required();

const useResetPassword = (): {
  control: Control<EmailConfirmationRequest, any>;
  resetSuccess: boolean;
  errorMessage: string[] | null;
  handleSubmit: UseFormHandleSubmit<EmailConfirmationRequest, undefined>;
  errors: FieldErrors<EmailConfirmationRequest>;
  onSubmit: (_data: EmailConfirmationRequest) => Promise<void>;
  handleClickBackPage: () => void;
} => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailConfirmationRequest>({
    defaultValues: { email: '', email_confirmation: '' },
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const [errorMessage, setErrorsMessage] = useState<string[] | null>(null);
  const [resetSuccess, setResetSuccess] = useState(false);
  const setIsLoading = useSetRecoilState(isLoading);

  const handleError = (error: any) => {
    const errorsMessage = getErrorsMessage(error);
    setErrorsMessage(errorsMessage);
  };

  const handleClickBackPage = () => {
    navigate(ROUTES.LOGIN);
  };

  const onSubmit = async (data: EmailConfirmationRequest) => {
    setIsLoading(true);
    setErrorsMessage(null);
    try {
      await AuthService.forgotPassword(data);
      setResetSuccess(true);
    } catch (error: any) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    control,
    errors,
    resetSuccess,
    errorMessage,
    handleSubmit,
    handleClickBackPage,
    onSubmit,
  };
};

export default useResetPassword;
