/**
 * PasswordStrength component
 */
// React
import React from 'react';

// Components
import { Box, styled } from '@mui/material';

// Helper
import { checkPasswordStrength } from 'utils/helper';

// Utils
const strengthBarColors = {
  1: '#ff0000',
  2: '#ffe48c',
  3: '#4caf50',
  4: '#2e7d32',
};

const strengthMessages = {
  1: '非常に弱い',
  2: '弱い',
  3: 'まあまあ',
  4: '非常に強い',
};

// Styled
const WrapperStrengthBar = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '20px',
  height: '15px',
  gap: '10px',
  margin: 0,
});

const BoxCustom = styled(Box)({
  width: 'max-content',
  textAlign: 'right',
  fontSize: '15px',
});

// interface
interface PasswordStrengthProps {
  password: string;
}

// Component
const PasswordStrength: React.FC<PasswordStrengthProps> = ({ password }) => {
  const strength = checkPasswordStrength(password);

  return (
    <Box sx={{ marginTop: '5px' }}>
      <WrapperStrengthBar>
        {Array.from({ length: 4 }).map((_, index) => (
          <Box
            key={index}
            sx={{
              position: 'relative',
              background: '#ccc',
              width: `${100 / 6}%`,
              height: '5px',
            }}
          >
            {index < strength && (
              <Box
                sx={{
                  background: strengthBarColors?.[strength] || '#ccc',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              ></Box>
            )}
          </Box>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'end', minWidth: '35%' }}>
          {strengthMessages?.[strength] && <BoxCustom>{strengthMessages[strength]}</BoxCustom>}
        </Box>
      </WrapperStrengthBar>
    </Box>
  );
};

export default PasswordStrength;
