/**
 * Change email verify page
 */

// React
import React from 'react';

// Libraries
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

// MUI imports
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Component
import AppLogo from 'components/AppLogo';

// Hook
import useLocalStorage from 'hooks/useLocalStorage';

// Recoil
import { isLoading } from 'recoil/LoadingRecoil';

// Router
import ROUTES from 'configs/route';

// Services
import AuthService from 'services/AuthService';

// Interface
import { FormData } from 'pages/Register';

// Utils
import { STORAGE_KEYS } from 'utils/constants';

// Styled
const ContainerCustom = styled(Container)({});

const LogoContainer = styled('div')({
  padding: '0 10px 40px 10px',
});

const WrapperChangeEmailForm = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '30px',
}));

const WrapperContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
});

const TypographyCustom = styled(Typography)({
  fontWeight: '600',
  marginBottom: '0',
  paddingBottom: '4px',
});

// Component
const ChangeEmailCompletedPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const setIsLoading = useSetRecoilState(isLoading);
  const [accessToken] = useLocalStorage(STORAGE_KEYS.ACCESS_TOKEN);
  const [userRegister, setUserRegister] = useLocalStorage<FormData>(STORAGE_KEYS.USER_REGISTER);
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const username = decodeURIComponent(searchParams.get('user_name') || '');
    const confirmation_code = searchParams.get('confirmation_code') || '';
    setUserRegister({ ...userRegister, username });
    const changeEmail = async () => {
      try {
        setIsLoading(true);
        await AuthService.changeEmailVerify({
          username,
          confirm_code: confirmation_code,
        });
        localStorage.clear();
        setIsLoading(false);
        navigate(ROUTES.CHANGE_EMAIL_COMPLETED);
      } catch (error) {
        setIsLoading(false);
        navigate(ROUTES.ERROR);
      }
    };
    if (userRegister?.username !== username && username && confirmation_code) changeEmail();
  }, [searchParams]);

  if (
    !!searchParams.get('user_name') === false ||
    !!searchParams.get('confirmation_code') === false ||
    !!accessToken === false
  ) {
    return <Navigate to={ROUTES.ERROR} state={{ from: location }} />;
  }

  return (
    <ContainerCustom>
      <WrapperChangeEmailForm>
        <LogoContainer>
          <AppLogo />
        </LogoContainer>
        <WrapperContent>
          <TypographyCustom variant="h3">メールの確認中</TypographyCustom>
          <TypographyCustom variant="h4">数分お待ちください</TypographyCustom>
        </WrapperContent>
      </WrapperChangeEmailForm>
    </ContainerCustom>
  );
};

export default ChangeEmailCompletedPage;
