/**
 * Footer component
 */

// React
import React, { FC } from 'react';

import { styled } from '@mui/material/styles';
import logoBlack from 'assets/images/artfreak-logo-black.svg';

const FooterContainer = styled('footer')({
  padding: '62px',
  width: '100%',
  textAlign: 'center',
});

const StyledImage = styled('img')(({ theme }) => ({
  width: 'min-content',
  height: '38px',
  [theme.breakpoints.down('sm')]: {
    height: '26px',
  },
}));

// Component
const Footer: FC = () => {
  return (
    <FooterContainer>
      <StyledImage src={logoBlack} alt="Omotas logo" />
    </FooterContainer>
  );
};

export default Footer;
