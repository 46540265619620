/**
 * Filed names
 */

export const FIELD_NAMES = {
  EMAIL: 'email',
  EMAIL_CONFIRMATION: 'email_confirmation',
  PASSWORD: 'password',
  PASSWORD_CONFIRM: 'password_confirm',
} as const;
