/**
 * Logout page
 */

// React
import React, { useEffect } from 'react';

// Library
import { useLocation, useNavigate } from 'react-router-dom';

// Material UI
import { Backdrop, CircularProgress } from '@mui/material';

// Router
import ROUTES from 'configs/route';

// Services
import AuthService from 'services/AuthService';

// Component
const LogoutPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const redirect_uri = queryParams.get('redirect_uri');

  useEffect(() => {
    if (redirect_uri) {
      const callAPI = async () => {
        try {
          await AuthService.logout();
          window.location.href = redirect_uri;
        } catch {
          navigate(ROUTES.ERROR);
        }
      };
      callAPI();
    }
  }, [redirect_uri]);

  return (
    <Backdrop sx={{ color: '#fff', zIndex: 99 }} open>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LogoutPage;
