/**
 * Auth route
 */

// React
import React, { useEffect } from 'react';

// Libraries
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

// Components
import AuthLayout from './AuthLayout';

// Recoil
import { isLoading } from 'recoil/LoadingRecoil';
import { userInfoAtom } from 'recoil/UserInfoRecoil';

// Hooks
import useLocalStorage from 'hooks/useLocalStorage';

// Utils
import { ROLE_USER, STORAGE_KEYS, PARAMS_GENERATE_AUTH_CODE } from 'utils/constants';
import { handleGenerateAuthenCode, validateUserAccess } from 'utils/helper';

// Router
import ROUTES from 'configs/route';

// Utils
import { AuthLayoutProps } from 'utils/types';

// Services
import AuthService from 'services/AuthService';

// Define the AuthRoute component
const AuthRoute: React.FC<AuthLayoutProps> = ({
  children,
  showHeader = true,
  showFooter = true,
  maxWidth,
  alignItems = 'start',
  showLogo = true,
  background,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const setIsLoading = useSetRecoilState(isLoading);
  const userData = useRecoilValue(userInfoAtom);

  const params = new URLSearchParams(window.location.search);
  const redirectUri = params.get('redirect_uri');
  const clientId = params.get('client_id');

  const [accessToken] = useLocalStorage(STORAGE_KEYS.ACCESS_TOKEN);

  useEffect(() => {
    if (accessToken && clientId && redirectUri) {
      if (
        redirectUri === PARAMS_GENERATE_AUTH_CODE.REDIRECT_URI &&
        userData?.role !== ROLE_USER.AF_USER
      ) {
        (async () => {
          try {
            setIsLoading(true);
            await AuthService.logout();
            setIsLoading(false);
          } catch (error) {
            console.error(error);
          }
        })();
      } else {
        (async () => {
          setIsLoading(true);

          const isValidAccess = await validateUserAccess(clientId, userData?.cognitoAuthSub);
          if (!isValidAccess) {
            navigate(ROUTES.NOT_FOUND);
            return;
          }

          const serviceUrl = await handleGenerateAuthenCode(clientId, redirectUri, navigate);
          if (serviceUrl) {
            window.location.href = serviceUrl;
          }
          setIsLoading(false);
        })();
      }
    }
  }, [accessToken]);

  if (accessToken && !clientId && !redirectUri) {
    return <Navigate to={ROUTES.DASHBOARD} state={{ from: location }} />;
  }

  // Authorized, so return the children wrapped with AppLayout
  return (
    <AuthLayout
      showHeader={showHeader}
      alignItems={alignItems}
      showFooter={showFooter}
      maxWidth={maxWidth}
      showLogo={showLogo}
      background={background}
    >
      {children}
    </AuthLayout>
  );
};

export default AuthRoute;
