/**
 * Private route
 */

// React
import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Components
import AppLayout from 'app/AppLayout';

// Hooks
import useLocalStorage from 'hooks/useLocalStorage';

// Utils
import { STORAGE_KEYS } from 'utils/constants';

// Router
import ROUTES from 'configs/route';

// Services
import AuthService from 'services/AuthService';

// Recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isLoading } from 'recoil/LoadingRecoil';
import { userInfoAtom } from 'recoil/UserInfoRecoil';

// Utils
import { CombinedRouteProps } from 'utils/types';

// Define the PrivateRoute component
const PrivateRoute: React.FC<CombinedRouteProps> = ({
  children,
  showHeader = true,
  showFooter = true,
  maxWidth,
  alignItems = 'start',
  background = '#FFFFFF',
}) => {
  const [accessToken] = useLocalStorage(STORAGE_KEYS.ACCESS_TOKEN);
  const setIsLoading = useSetRecoilState(isLoading);
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const location = useLocation();

  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const user = await AuthService.getUserInfo();

      setUserInfo({ ...user });
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (accessToken && !userInfo?.id) {
      fetchUserData();
    }
  }, [accessToken]);

  if (!accessToken) {
    // Not logged in, redirect to login page with the return url
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} />;
  }

  // Authorized, so return the children wrapped with AppLayout
  return (
    <AppLayout
      showHeader={showHeader}
      alignItems={alignItems}
      showFooter={showFooter}
      maxWidth={maxWidth}
      background={background}
    >
      {children}
    </AppLayout>
  );
};

export default PrivateRoute;
