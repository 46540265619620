/**
 * Application
 */

// React
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

// Recoil
import { RecoilRoot } from 'recoil';

// Material config
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme } from 'configs/theme';

// Components
import AppRouter from 'app/AppRouter';
import AppContainer from 'app/AppContainer';

// Render application
const App: React.FC = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <AppContainer>
        <RecoilRoot>
          <Suspense fallback={<div></div>}>
            <BrowserRouter>
              <AppRouter />
            </BrowserRouter>
          </Suspense>
        </RecoilRoot>
      </AppContainer>
    </ThemeProvider>
  );
};

export default App;
