/**
 * useLogin hook
 */

// React
import React, { useEffect, useState } from 'react';

// Libraries
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useLocation } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { Control, FieldErrors, useForm, UseFormHandleSubmit } from 'react-hook-form';

// Utils
import { LoginFormData } from 'utils/types';
import { VALIDATION_MESSAGES, STORAGE_KEYS } from 'utils/constants';
import { createEmailSchema, createPasswordSchema } from 'utils/validation';
import { getErrorsMessage, handleLoginFromClient, handleLoginFromSSO } from 'utils/helper';

// Hooks
import useLocalStorage from 'hooks/useLocalStorage';

// Recoil
import { isLoading } from 'recoil/LoadingRecoil';
import { userInfoAtom } from 'recoil/UserInfoRecoil';
import { snackNotifyAtom } from 'recoil/SnackNotifyRecoil';

// Type
import { LoginRequest } from 'models/LoginRequest';

// Custom validation schema
const schema = yup
  .object({
    email: createEmailSchema(),
    password: createPasswordSchema(),
  })
  .required();

const useLogin = (): {
  control: Control<LoginFormData, any>;
  handleSubmit: UseFormHandleSubmit<LoginFormData, undefined>;
  handleLogin: (_data: LoginFormData) => Promise<void>;
  errors: FieldErrors<LoginFormData>;
  errorMessage: string[] | null;
  handleNavigate: (_route: string) => void;
} => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const redirectUri = params.get('redirect_uri');
  const clientId = params.get('client_id');

  const [errorMessage, setErrorsMessage] = useState<string[] | null>(null);
  const location = useLocation();

  const resetUserInfo = useResetRecoilState(userInfoAtom);
  const setUserInfo = useSetRecoilState(userInfoAtom);
  const setIsLoading = useSetRecoilState(isLoading);
  const setSnackNotify = useSetRecoilState(snackNotifyAtom);

  const [, setAccessToken] = useLocalStorage(STORAGE_KEYS.ACCESS_TOKEN);
  const [, setRefreshToken] = useLocalStorage(STORAGE_KEYS.REFRESH_TOKEN);
  const [previousPageAddress, setPreviousPageAddress] = useLocalStorage(
    STORAGE_KEYS.PREVIOUS_PAGE_ADDRESS
  );

  const handleLogin = async (data: LoginRequest) => {
    resetUserInfo();
    setErrorsMessage(null);
    try {
      setIsLoading(true);
      if (redirectUri && clientId) {
        await handleLoginFromClient(data, clientId, redirectUri, handleLoginError);
        return;
      }
      await handleLoginFromSSO(
        data,
        setAccessToken,
        setRefreshToken,
        setUserInfo,
        setIsLoading,
        navigate,
        handleLoginError
      );
    } catch (error: any) {
      handleLoginError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginError = (error: any) => {
    const errorsMessage = getErrorsMessage(error);
    setErrorsMessage(errorsMessage);
  };

  const handleNavigate = (route: string) => {
    if (previousPageAddress !== location.pathname) {
      setPreviousPageAddress(location.pathname);
    }
    navigate(route);
  };

  useEffect(() => {
    if (sessionStorage.getItem(STORAGE_KEYS.IS_LIMIT_EXCEEDED)) {
      setSnackNotify({
        open: true,
        severity: 'warning',
        title: (
          <div>
            {VALIDATION_MESSAGES.ACCOUNT.LOCK}
            <br />
            {VALIDATION_MESSAGES.ACCOUNT.ACTION}
          </div>
        ),
      });
      sessionStorage.removeItem(STORAGE_KEYS.IS_LIMIT_EXCEEDED);
    }
  }, []);

  return {
    control,
    errorMessage,
    errors,
    handleSubmit,
    handleLogin,
    handleNavigate,
  };
};

export default useLogin;
