/**
 * RegisterTemporary page
 */

// React
import React, { FC } from 'react';

// Material
import { Typography } from '@mui/material';

// Validation
import { useRegisterTem } from './useRegisterTem';
import {
  FieldContainer,
  FieldErrorContainer,
  FormButton,
  FormContainer,
  TypographyCustom,
  WrapperContent,
  WrapperForm,
  WrapperInputOrMessage,
} from 'styles';

// Hook
import AppInput from 'components/common/AppInput';

// Constants
import {
  DATE_TIME_FORMATS,
  FIELD_NAMES,
  FORM_BUTTON,
  FORM_PLACEHOLDER,
  FORM_REGISTER_TEMPORARY_TITLE_HEADER,
  FORM_REGISTER_TEMPORARY_TITLE_HEADER_SUCCESS,
  FORM_TOOLTIP,
} from 'utils/constants';

// Utils
import { convertTimestampToDateTime, parseMessage } from 'utils/helper';

// Form fields
const formRegisterTem = [
  {
    name: FIELD_NAMES.EMAIL,
    placeholder: FORM_PLACEHOLDER.EMAIL_REGISTER_TEMPORARY,
    type: 'email',
  },
  {
    name: FIELD_NAMES.EMAIL_CONFIRMATION,
    placeholder: FORM_PLACEHOLDER.EMAIL_REGISTER_TEMPORARY_CONFIRMATION,
    type: 'email',
    titleTooltip: FORM_TOOLTIP.EMAIL_CONFIRM,
  },
];

// RegisterTemporary component
const RegisterTemporary: FC = () => {
  const {
    control,
    handleSubmit,
    errors,
    isRegisterEmailSuccess,
    handleBackPreviousPage,
    onSubmit,
    expiredTime,
    errorMessage,
  } = useRegisterTem();

  const expiredTimeWithFormat = expiredTime
    ? convertTimestampToDateTime(+expiredTime, DATE_TIME_FORMATS.JAPANESE)
    : '〜〜〜';
  const { HEADING, DESCRIPTIONS } = isRegisterEmailSuccess
    ? FORM_REGISTER_TEMPORARY_TITLE_HEADER_SUCCESS
    : FORM_REGISTER_TEMPORARY_TITLE_HEADER;

  const renderFormRegisterTem = () => {
    return (
      <WrapperForm>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <WrapperContent alignItems="start">
            <TypographyCustom variant="h2">{HEADING}</TypographyCustom>
            {DESCRIPTIONS?.map((description, index) => (
              <Typography key={index} variant="h3">
                {description}
              </Typography>
            ))}
          </WrapperContent>

          {formRegisterTem.map(({ name, placeholder, type, titleTooltip }) => (
            <FieldContainer key={name}>
              <AppInput
                name={name}
                control={control}
                type={type}
                errors={errors}
                titleTooltip={titleTooltip}
                helperText={errors?.[name]?.message}
                placeholder={placeholder}
              />
            </FieldContainer>
          ))}

          {errorMessage && (
            <FieldErrorContainer>
              <WrapperInputOrMessage sx={{ width: 'max-content' }}>
                <Typography variant="h4" fontWeight="bold" textAlign="start">
                  {errorMessage}
                </Typography>
              </WrapperInputOrMessage>
            </FieldErrorContainer>
          )}

          <FormButton variant="contained" type="submit" color="secondary">
            {FORM_BUTTON.SEND}
          </FormButton>
        </FormContainer>
      </WrapperForm>
    );
  };

  const renderRegisterEmailSuccess = () => {
    return (
      <WrapperForm>
        <FormContainer>
          <WrapperContent alignItems="center">
            <TypographyCustom variant="h2">{HEADING}</TypographyCustom>
            {DESCRIPTIONS?.map((description, index) => (
              <Typography key={index} variant="h3">
                {parseMessage(description, [expiredTimeWithFormat])}
              </Typography>
            ))}
          </WrapperContent>
          <FormButton
            onClick={handleBackPreviousPage}
            variant="contained"
            type="button"
            color="secondary"
          >
            {FORM_BUTTON.BACK}
          </FormButton>
        </FormContainer>
      </WrapperForm>
    );
  };

  return isRegisterEmailSuccess ? renderRegisterEmailSuccess() : renderFormRegisterTem();
};

export default RegisterTemporary;
