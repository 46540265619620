/**
 * Service config
 */

// Axios
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axiosRequestConfig from './axios';

// Utils
import { STORAGE_KEYS, HTTP_CODE, TIME_LOCKED } from 'utils/constants';

// Libraries
import SuperTokensLock from 'browser-tabs-lock';

// Router
import ROUTES from 'configs/route';

// Utils
import { isTokenExpired } from 'utils/helper';

// Services
import AuthService from 'services/AuthService';

export default class BaseService {
  private axiosInstance: AxiosInstance;
  private superTokensLock = new SuperTokensLock();

  constructor() {
    this.axiosInstance = axios.create(axiosRequestConfig);

    this.axiosInstance.interceptors.response.use(
      (res) => res,
      async (error) => {
        const originalRequest = error.config;
        if (
          error?.response?.status === HTTP_CODE.UNAUTHORIZED &&
          !originalRequest._retry &&
          ROUTES.CHANGE_PASSWORD_COMPLETED !== window.location.pathname &&
          ROUTES.CHANGE_EMAIL_COMPLETED !== window.location.pathname
        ) {
          originalRequest._retry = true;
          const newAccessToken = await this.getAccessToken(true);
          if (newAccessToken) {
            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return await this.axiosInstance(originalRequest);
          } else {
            window.location.pathname = ROUTES.LOGIN;
            localStorage.clear();
          }
        }
        return Promise.reject(error);
      }
    );
  }

  /**
   * Sends an HTTP request
   * @param endpoints Array containing the URL path and HTTP method
   * @param pathParams Parameters to be replaced in the URL path
   * @param data Data to be sent in the request body
   * @param params Query parameters for the request
   * @returns A Promise that resolves to the HTTP response
   */
  public async request(
    endpoints: string[] = [],
    pathParams: any[] = [],
    data: any = null,
    params: any = null
  ): Promise<AxiosResponse<any>> {
    if (!this.axiosInstance) {
      throw new Error('Axios instance not initialized');
    }
    const config = await this.getConfig(endpoints, pathParams, data, params);

    return this.axiosInstance(config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * Configures the HTTP request
   * @param endpoints Array containing the URL path and HTTP method
   * @param pathParams Parameters to be replaced in the URL path
   * @param data Data to be sent in the request body
   * @param params Query parameters for the request
   * @returns AxiosRequestConfig object
   */
  private async getConfig(
    endpoints: string[] = [],
    pathParams: any[] = [],
    data: any = null,
    params: any = null
  ): Promise<AxiosRequestConfig> {
    const [path, method] = endpoints;
    const pathBind = this.bindPath(path, pathParams);
    const accessToken = await this.getAccessToken();

    return {
      url: pathBind,
      method: method as any,
      data: data,
      params: params,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }

  public async getAccessToken(unAuthor = false): Promise<string | undefined> {
    const accessToken = localStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);

    if (accessToken) {
      const tokenExpired = isTokenExpired();
      if (tokenExpired || unAuthor) {
        try {
          if (await this.superTokensLock.acquireLock('refresh_token', TIME_LOCKED)) {
            const newAccessToken = await AuthService.refreshToken({
              refresh_token: this.getRefreshToken(),
            });

            if (newAccessToken) {
              localStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, JSON.stringify(newAccessToken));
              await this.superTokensLock.releaseLock('refresh_token');
              return newAccessToken;
            }
            await this.superTokensLock.releaseLock('refresh_token');
          }
        } catch (error) {
          window.location.pathname = ROUTES.LOGIN;
          localStorage.clear();
        }
      }
      return JSON.parse(accessToken);
    }
    return undefined;
  }

  public getRefreshToken(): string {
    const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
    return refreshToken ? JSON.parse(refreshToken) : '';
  }

  /**
   * Replaces placeholders in the URL path with actual path parameters
   * @param path The URL path with placeholders
   * @param pathParams The path parameters to replace placeholders
   * @returns The URL path with placeholders replaced
   */
  private bindPath(path: string, pathParams: any[] = []): string {
    if (!Array.isArray(pathParams) || !pathParams.length) {
      return path;
    }
    // Replace placeholders in path
    return path.replace(/\$\d/g, (match) => {
      const idx = parseInt(match.replace('$', ''), 10);
      return pathParams[idx] || match;
    });
  }
}
