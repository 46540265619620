import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { FieldErrorContainer, WrapperInputOrMessage } from 'styles/commonStyles';

interface AppFieldErrorsProps {
  errorMessage: string[] | null;
}

export const AppFieldErrors: FC<AppFieldErrorsProps> = ({ errorMessage }) => {
  if (!errorMessage) return <></>;

  return (
    <FieldErrorContainer>
      <WrapperInputOrMessage sx={{ width: 'max-content' }}>
        {errorMessage?.map((error) => (
          <Typography key={error} variant="h4" fontWeight="bold" textAlign="start">
            {error}
          </Typography>
        ))}
      </WrapperInputOrMessage>
    </FieldErrorContainer>
  );
};
