/**
 * Loading component
 */

// React
import React from 'react';

// Library
import { useRecoilValue } from 'recoil';

// MUI Components
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from '@mui/material';

// Recoil
import { initIsLoading } from 'recoil/LoadingRecoil';

// Component
const Loading: React.FC = () => {
  const loading = useRecoilValue(initIsLoading);

  return (
    <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;
