/**
 * Axios config
 */

import axios, { AxiosRequestConfig } from 'axios';

const cancelTokenSource = axios.CancelToken.source();

const axiosRequestConfig: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/api/v1`,
  responseType: 'json',
  timeout: 30000,
  cancelToken: cancelTokenSource.token,
};

export default axiosRequestConfig;
