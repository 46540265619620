/**
 * Snack notify recoil
 */

// Recoil
import { atom } from 'recoil';

// Utils
import { SnackNotifyProps } from 'utils/types/atomTypes';

export const snackNotifyAtom = atom<SnackNotifyProps>({
  key: 'snackNotifyAtom',
  default: {
    open: false,
    title: '',
    severity: 'success',
    autoHideDuration: 3000,
    handleClose: () => {},
  },
});
