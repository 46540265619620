/**
 * AppPassword component
 */

// React
import React, { useEffect, useState } from 'react';

// Library
import { Controller, Control, FieldErrors, FieldValues, Path } from 'react-hook-form';

// Material
import { InputAdornment, IconButton, TextFieldProps } from '@mui/material';
import {
  VisibilityOffOutlined as VisibilityOff,
  VisibilityOutlined as Visibility,
} from '@mui/icons-material';

// Component
import TooltipInput from '../TooltipInput';

// Utils
import { CustomTextField } from 'styles';
import PasswordStrength from 'components/PasswordStrength';

// Define a generic type T for form data, constrained to FieldValues
type PasswordInputProps<T extends FieldValues> = TextFieldProps & {
  name: Path<T>;
  control: Control<T>;
  errors?: FieldErrors<T>;
  titleTooltip?: string;
  passwordStrength?: boolean;
  hideIcon?: boolean;
};

// Component
const AppPassword = <T extends FieldValues>({
  name,
  control,
  errors,
  titleTooltip,
  passwordStrength = false,
  hideIcon = false,
  ...props
}: PasswordInputProps<T>): JSX.Element => {
  const { disabled } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    if (!disabled) {
      setShowPassword((prev) => !prev);
    }
  };
  const Icon = !showPassword ? VisibilityOff : Visibility;

  useEffect(() => {
    if (disabled) {
      setShowPassword(false);
    }
  }, [disabled]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...restField } }) => (
        <TooltipInput titleTooltip={titleTooltip}>
          <CustomTextField
            {...restField}
            inputRef={ref}
            type={showPassword ? 'text' : 'password'}
            error={!!errors?.[name]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ opacity: hideIcon ? '0' : '1' }}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    disabled={disabled}
                  >
                    <Icon
                      sx={{
                        fontSize: {
                          xs: '20px',
                          md: '24px',
                        },
                        color: (theme) => theme.palette.primary.main,
                        transform: 'rotateX(180deg)',
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            fullWidth
            {...props}
          />
          {passwordStrength && !!restField.value && !disabled && (
            <PasswordStrength password={restField.value} />
          )}
        </TooltipInput>
      )}
    />
  );
};

export default AppPassword;
