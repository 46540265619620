/**
 * Tooltip mobile
 */

// React
import React, { FC } from 'react';

// Material
import { Box, Typography } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { styled } from '@mui/material/styles';

interface MobileTooltipProps {
  titleTooltip: string;
}

// Styled
const TooltipContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  marginTop: '15px',
  gap: '6px',
  backgroundColor: theme.palette.info.main,
  borderRadius: '20px',
  padding: '4px 14px 4px 4px',
}));

const TooltipArrow = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '99%',
  left: '20px',
  width: 0,
  height: 0,
  borderLeft: '6px solid transparent',
  borderRight: '6px solid transparent',
  borderBottom: `6px solid ${theme.palette.info.main}`,
}));

const IconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '18px',
  height: '18px',
  borderRadius: '50%',
  background: 'white',
});

// Component
const MobileTooltip: FC<MobileTooltipProps> = ({ titleTooltip }) => {
  return (
    <TooltipContainer>
      <TooltipArrow />
      <IconWrapper>
        <PriorityHighIcon sx={{ fontSize: '12px' }} />
      </IconWrapper>
      <Typography variant="h5" fontWeight="bold">
        {titleTooltip}
      </Typography>
    </TooltipContainer>
  );
};

export default MobileTooltip;
