/**
 * 404 page
 */

// React
import React from 'react';

// Libraries
import { useNavigate } from 'react-router-dom';

// MUI imports
import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Router
import ROUTES from 'configs/route';

// Styled
const ContainerCustom = styled(Container)({
  paddingTop: '15%',
});

const ErrorContainer = styled(Box)(({ theme }) => ({
  fontSize: '32px',
  fontWeight: '600',
  color: theme.palette.primary.main,
  padding: '0 10px 20px 10px',
}));

const WrapperBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '30px',
}));

const WrapperContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '5px',
});

const TypographyCustom = styled(Typography)({
  fontWeight: '400',
  marginBottom: '0',
  paddingBottom: '4px',
});

const WrapperButton = styled(Box)({
  width: '260px',
  marginTop: '20px',
});

// Component
const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClickBackPage = async () => {
    try {
      navigate(ROUTES.HOME);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ContainerCustom>
      <WrapperBox>
        <ErrorContainer>404</ErrorContainer>
        <WrapperContent>
          <Box paddingBottom="20px">
            <TypographyCustom variant="h2">お探しのページは見つかりませんでした</TypographyCustom>
          </Box>
          <TypographyCustom variant="h4">
            該当のページはアドレスが変更されたか、ページが削除された可能性があります。
          </TypographyCustom>
          <TypographyCustom variant="h4">
            URLに間違いがないか、再度ご確認ください。
          </TypographyCustom>
        </WrapperContent>
        <WrapperButton>
          <Button onClick={handleClickBackPage} variant="contained" fullWidth>
            トップに戻る
          </Button>
        </WrapperButton>
      </WrapperBox>
    </ContainerCustom>
  );
};

export default NotFoundPage;
