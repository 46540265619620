/**
 * App banner component
 */

// React
import React, { FC } from 'react';

// Library
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Material
import { styled } from '@mui/material/styles';
import { Box, useMediaQuery, useTheme } from '@mui/material';

// Utils
import { AWS_S3 } from 'utils/constants';
import { BannerType } from 'utils/types';

// Settings react-slick
const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 5000,
  waitForAnimate: false,
  arrows: false,
};

// Styled
const BannerContainer = styled('img')(({ theme }) => ({
  height: 'auto',
  width: '100%',
  borderRadius: '10px',
  marginBottom: '10px',
  [theme.breakpoints.down('sm')]: {
    height: '280px',
  },
}));

interface BannerProps {
  banners: BannerType[];
}

// Component
const Banner: FC<BannerProps> = ({ banners }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Slider {...settings}>
      {banners?.map((banner: any, index) => (
        <Box key={index}>
          <BannerContainer
            src={`${AWS_S3.BASE_URL}/${isSmallScreen ? banner?.mobile?.name : banner?.desktop?.name}`}
          />
        </Box>
      ))}
    </Slider>
  );
};

export default Banner;
