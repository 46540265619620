/**
 * App logo component
 */

// React
import React from 'react';

import { styled } from '@mui/material/styles';

// Utils
import { AWS_S3 } from 'utils/constants';

const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: '44px',
  [theme.breakpoints.down('sm')]: {
    height: '34px',
  },
}));

// Component
const Header: React.FC = () => {
  return <Image src={`${AWS_S3.LOGO_SVG}`} alt="Omotas logo" />;
};

export default Header;
