/**
 * Max length filed input
 */

export const MAX_LENGTHS = {
  MAX_5: 5,
  MAX_8: 8,
  MAX_10: 10,
  MAX_12: 12,
  MAX_15: 15,
  MAX_30: 30,
  MAX_32: 32,
  MAX_50: 50,
  MAX_100: 100,
  MAX_200: 200,
  MAX_255: 255,
};

export const VALIDATION_ATTRIBUTES = {
  EMAIL: 'メールアドレス',
  PASSWORD: 'パスワード',
  COMPANY_NAME: '会社名 / 団体名',
  DEPARTMENT: '所属',
  LAST_NAME: '姓',
  FIRST_NAME: '名',
  LAST_NAME_KANA: 'セイ',
  FIRST_NAME_KANA: 'メイ',
  ZIPCODE: '郵便番号',
  PREFECTURE: '都道府県',
  CITY: '市区町村',
  ADDRESS: '番地',
  BUILDING: '建物',
  COMPANY_PHONE_NUMBER: '会社電話番号',
  PHONE_NUMBER: '携帯電話番号',
};
