// React
import React, { FC } from 'react';

// MUI
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';

// components
import AppPassword from 'components/common/AppPassword';
import { Zipcode } from '../Zipcode';
import AppInput from 'components/common/AppInput';

// models
import { FormRegister } from 'models/RegisterRequest';

// Libraries
import { Controller } from 'react-hook-form';

// Styles
import { CustomTextField } from 'styles/commonStyles';

// Utils
import { renderErrorMessagePassword } from 'utils/helper';
import { ReactHookFormType } from 'utils/types';
import { FORM_TOOLTIP } from 'utils/constants';

interface UserInfoProps extends ReactHookFormType<FormRegister> {
  disableForm?: boolean;
  disableCompanyName?: boolean;
  isUpdateForm?: boolean;
}

// Styles
const TypographyCustom = styled(Typography)({
  fontWeight: '700',
});

const TypographyNotRequire = styled(TypographyCustom)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

// UserInfo component
const UserInfo: FC<UserInfoProps> = ({
  control,
  errors,
  setError,
  clearErrors,
  setValue,
  disableForm = false,
  disableCompanyName,
  isUpdateForm,
}) => {
  let tooltipTitle = '';

  if (!disableForm) {
    tooltipTitle = isUpdateForm ? FORM_TOOLTIP.PASSWORD_UPDATE_USER : FORM_TOOLTIP.PASSWORD;
  }

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <TypographyCustom mb={1}>メールアドレス</TypographyCustom>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <CustomTextField
              className="input-field-email-disable"
              {...field}
              variant="outlined"
              fullWidth
              disabled
            />
          )}
        />
      </Grid>

      <Grid container size={12} spacing={1}>
        <TypographyCustom>パスワード</TypographyCustom>
        <Grid size={12}>
          <Box>
            <AppPassword
              name="password"
              control={control}
              errors={errors}
              placeholder="半角英数字10〜32文字"
              disabled={disableForm}
              titleTooltip={tooltipTitle}
              passwordStrength
              hideIcon={disableForm}
            />
            {!disableForm && <>{renderErrorMessagePassword(errors)}</>}
          </Box>
        </Grid>
        <Grid size={12}>
          <Box sx={{ display: disableForm ? 'none' : 'block' }}>
            <AppPassword
              name="password_confirmation"
              control={control}
              errors={errors}
              placeholder="確認のためもう一度入力してください"
              disabled={disableForm}
              helperText={errors?.password_confirmation?.message}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid size={12}>
        <TypographyCustom mb={1}>会社名 / 団体名</TypographyCustom>
        <AppInput
          name="company_name"
          control={control}
          errors={errors}
          placeholder="株式会社アートフリーク"
          helperText={errors?.company_name?.message}
          disabled={disableForm || disableCompanyName}
        />
      </Grid>

      <Grid size={12}>
        <Box display="flex" mb={1}>
          <TypographyCustom>所属</TypographyCustom>
          <TypographyNotRequire>（任意）</TypographyNotRequire>
        </Box>

        <AppInput
          placeholder="マーケティング部"
          name="department"
          control={control}
          errors={errors}
          helperText={errors.department?.message}
          disabled={disableForm}
        />
      </Grid>

      <Grid container size={12} spacing={1}>
        <TypographyCustom>お名前</TypographyCustom>
        <Grid container size={12} spacing={1}>
          <Grid size={6}>
            <AppInput
              placeholder="姓"
              name="last_name"
              control={control}
              errors={errors}
              helperText={errors.last_name?.message}
              disabled={disableForm}
            />
          </Grid>
          <Grid size={6}>
            <AppInput
              placeholder="名"
              name="first_name"
              control={control}
              errors={errors}
              helperText={errors.first_name?.message}
              disabled={disableForm}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container size={12} spacing={1}>
        <TypographyCustom>フリガナ</TypographyCustom>
        <Grid container size={12} spacing={1}>
          <Grid size={6}>
            <AppInput
              placeholder="セイ"
              name="last_name_kana"
              control={control}
              errors={errors}
              helperText={errors.last_name_kana?.message}
              disabled={disableForm}
            />
          </Grid>
          <Grid size={6}>
            <AppInput
              placeholder="メイ"
              name="first_name_kana"
              control={control}
              errors={errors}
              helperText={errors.first_name_kana?.message}
              disabled={disableForm}
            />
          </Grid>
        </Grid>
      </Grid>
      <Zipcode
        control={control}
        errors={errors}
        setError={setError}
        clearErrors={clearErrors}
        setValue={setValue}
        disable={disableForm}
        titleTooltip={disableForm || isUpdateForm ? '' : FORM_TOOLTIP.ZIP_CODE}
      />

      <Grid size={12}>
        <Box display="flex" mb={1}>
          <TypographyCustom>会社電話番号</TypographyCustom>
          <TypographyNotRequire>（任意）</TypographyNotRequire>
        </Box>

        <AppInput
          name="company_phone_number"
          control={control}
          placeholder="09012345678"
          validatePhone
          errors={errors}
          titleTooltip={disableForm || isUpdateForm ? '' : FORM_TOOLTIP.COMPANY_PHONE_NUMBER}
          helperText={errors.company_phone_number?.message}
          disabled={disableForm}
        />
      </Grid>

      <Grid size={12}>
        <TypographyCustom mb={1}>携帯電話番号</TypographyCustom>
        <AppInput
          name="phone_number"
          control={control}
          errors={errors}
          placeholder="09012345678"
          titleTooltip={disableForm || isUpdateForm ? '' : FORM_TOOLTIP.PHONE_NUMBER}
          validatePhone
          helperText={errors.phone_number?.message}
          disabled={disableForm}
        />
      </Grid>
    </Grid>
  );
};

export default UserInfo;
